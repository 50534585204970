import React, { Component } from 'react'
import { Input, DatePicker, Select, Form, Checkbox } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { isAlumni, studentStatusConf, educationConf, positionConf, bkConf, ssConf, bsConf } from '../../../utils/config'
import countryConf from '../../../utils/countries.json'
import SelectWithOther from '../../../components/SelectWithOther'
import config from '../config'
import { AFTERTODAY } from '../../../utils/config'
import { bindRef } from '../../../utils/bindForward'
import './index.less'

const { Option } = Select;
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const formItemLayoutWithoutLabel = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16, offset: 8 },
  },
};

const excultList = ['硕士在读', '硕士', '博士在读', '博士']

// 选择对应的学院和专业列表
const eduFilterList = [{
  value: '本科',
  confs: bkConf
}, {
  value: '硕士',
  confs: ssConf
}, {
  value: '博士',
  confs: bsConf
}]

const notInEducation = ['本科在读', '硕士在读', '博士在读']

const plainOptions = positionConf.map(v => ({ label: v.name, value: v.id }))

@bindRef

class Investment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itemStatus: {}
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.initValues && Object.keys(state.itemStatus).length === 0) {
      const obj = {}
      // props.initValues.forEach((item, index) => obj[index] = { ...item, majorList: (bkConf.find(v => v.faculty === item.faculty) || {}).major || [] })
      props.initValues.forEach((item, index) => obj[index] = item)
      return {
        itemStatus: obj
      }
    }
    return null
  }

  handleChange = (value, key, type) => {
    const { itemStatus } = this.state;
    const { forwardedRef: { current: { getFieldValue, setFieldsValue } } } = this.props
    const teams = getFieldValue('teams')
    const curItem = itemStatus[key] || {}
    if (type === 'studentStatus') {
      setFieldsValue({
        teams: teams.map((v, index) => {
          if (index === key) {
            return {
              ...v,
              education: null,
              major: null,
              faculty: null,
            }
          }
          return v;
        })
      })
      this.setState({
        itemStatus: {
          ...itemStatus,
          [key]: {
            ...curItem,
            [type]: value,
            education: null,
          }
        }
      })
    } else {
      if (type === 'faculty') {
        setFieldsValue({
          teams: teams.map((v, index) => {
            if (index === key) {
              return {
                ...v,
                major: null
              }
            }
            return v;
          })
        })
      } else if (type === 'education') {
        setFieldsValue({
          teams: teams.map((v, index) => {
            if (index === key) {
              return {
                ...v,
                major: null,
                faculty: null,
              }
            }
            return v;
          })
        })
      }
      this.setState({
        itemStatus: {
          ...itemStatus,
          [key]: {
            ...curItem,
            [type]: value
          }
        }
      })
    }
  }

  render() {
    const { itemStatus } = this.state;
    const { fields, add, remove, colleges } = this.props;
    const OtherOptions = <Option key={'其他'} value={'其他'}>其他</Option>
    return (
      <div ref={this.props.forwardedRef}>
        {fields.map(({ key, name, fieldKey, ...restField }) => {
          const curItem = itemStatus[name] || {}
          const xueyuanList = curItem.education ? (eduFilterList.find(v => curItem.education.includes(v.value)) || {}).confs || [] : []
          const zhuanyeList = (xueyuanList.find(v => v.faculty === curItem.faculty) || {}).major || []
          const hustEducationList = [2, 3].includes(curItem.studentStatus) ? educationConf.filter(v => !notInEducation.includes(v.id)) : educationConf;
          return (
            <div>
              <h2>{`成员${name + 1}`}</h2>
              <div key={key} className='card'>
                <FormItem wrapperCol={{
                  xs: { span: 24, offset: 0 },
                  sm: { span: 20, offset: 2 },
                }} style={{ width: '84%', marginBottom: 0 }}  {...restField}>

                  <FormItem {...formItemLayout} label='姓名' fieldKey={[fieldKey, 'name']} name={[name, 'name']}  {...config.member_name}>
                    <Input placeholder='请输入姓名' />
                  </FormItem>
                  <FormItem {...formItemLayout} label='国籍' fieldKey={[fieldKey, 'nationality']} name={[name, 'nationality']} {...config.member_nationality} >
                    <Select placeholder='请选择' showSearch filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                      {countryConf.map(v => <Option key={v.id} value={v.cname}>{v.cname}</Option>)}
                    </Select>
                  </FormItem>
                  <FormItem {...formItemLayout} label='手机号码' fieldKey={[fieldKey, 'contactNumber']} name={[name, 'contactNumber']} {...config.member_contactNumber}>
                    <Input placeholder='请输入手机号码' />
                  </FormItem>
                  <FormItem {...formItemLayout} label='电子邮箱' fieldKey={[fieldKey, 'email']} name={[name, 'email']} {...config.member_email}>
                    <Input placeholder='请输入电子邮箱' />
                  </FormItem>
                  <FormItem {...formItemLayout} label='是否华中大教师' fieldKey={[fieldKey, 'isTeacher']} name={[name, 'isTeacher']} {...config.member_isTeacher}>
                    <Select placeholder='请选择'>
                      {isAlumni.map(v => <Option key={v.id} value={v.value}>{v.name}</Option>)}
                    </Select>
                  </FormItem>
                  <FormItem {...formItemLayout} label='是否校友' fieldKey={[fieldKey, 'isAlumnus']} name={[name, 'isAlumnus']} {...config.member_isAlumnus}>
                    <Select placeholder='请选择' onChange={(e) => this.handleChange(e, name, 'isAlumnus')}>
                      {isAlumni.map(v => <Option key={v.id} value={v.value}>{v.name}</Option>)}
                    </Select>
                  </FormItem>
                  {curItem.isAlumnus !== null &&
                    <>
                      {curItem.isAlumnus === true && <>
                        <FormItem {...formItemLayout} label='毕业情况' fieldKey={[fieldKey, 'studentStatus']} name={[name, 'studentStatus']} {...config.member_studentStatus}>
                          <Select placeholder='请选择' onChange={(e) => this.handleChange(e, name, 'studentStatus')}>
                            {studentStatusConf.map(v => <Option key={v.id} value={v.id}>{v.name}</Option>)}
                          </Select>
                        </FormItem>
                        <FormItem {...formItemLayout} label='在华中大学历层次' fieldKey={[fieldKey, 'education']} name={[name, 'education']} {...config.member_education}>
                          <Select placeholder='请选择' onChange={(e) => this.handleChange(e, name, 'education')}>
                            {hustEducationList.map(v => <Option key={v.id} value={v.id}>{v.name}</Option>)}
                          </Select>
                        </FormItem>
                        {curItem.education && <>
                          <FormItem {...formItemLayout} label='所在院系' fieldKey={[fieldKey, 'faculty']} name={[name, 'faculty']} {...config.member_faculty}>
                            {curItem.education === '大专' ?
                              <Input placeholder='请输入院系名称' />
                              : <SelectWithOther placeholder='请输入所在院系' onChange={(e) => this.handleChange(e, name, 'faculty')} showSearch>
                                {xueyuanList.map(v => <Option key={v.faculty} value={v.faculty}>{v.faculty}</Option>)}
                                {OtherOptions}
                              </SelectWithOther>}
                          </FormItem>
                          <FormItem {...formItemLayout} label='专业名称' fieldKey={[fieldKey, 'major']} name={[name, 'major']} {...config.member_major}>
                            {curItem.education === '大专' ?
                              <Input placeholder='请输入专业名称' />
                              : <SelectWithOther placeholder='请输入专业名称' showSearch>
                                {zhuanyeList.map(v => <Option key={v} value={v}>{v}</Option>)}
                                {OtherOptions}
                              </SelectWithOther>}
                          </FormItem>
                          {excultList.includes(curItem.education) && <FormItem {...formItemLayout} label='导师' fieldKey={[fieldKey, 'tutor']} name={[name, 'tutor']} {...config.member_major}>
                            <Input placeholder='请输入导师姓名' />
                          </FormItem>}
                        </>}
                        <FormItem {...formItemLayout} label='入学年份' fieldKey={[fieldKey, 'enterDate']} name={[name, 'enterDate']} {...config.member_enterDate}>
                          <DatePicker picker="month" disabledDate={AFTERTODAY} />
                        </FormItem>
                      </>}
                      {(curItem.isAlumnus === false || (curItem.isAlumnus === true && curItem.studentStatus && curItem.studentStatus === 3)) && <>
                        <FormItem {...formItemLayout} label='最后在读/毕业学校名称' fieldKey={[fieldKey, 'highestUniversity']} name={[name, 'highestUniversity']} {...config.member_highestUniversity}>
                          <Select
                            placeholder='请选择'
                            showSearch
                            onSearch={this.handleSearch}
                            filterOption={(input, option) =>
                              option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {(colleges || []).map(v => <Option key={v.id} value={v.nameCN || v.name}>{v.nameCN || v.name}</Option>)}
                          </Select>
                        </FormItem>
                        <FormItem {...formItemLayout} label='最后在读/毕业学历层次' fieldKey={[fieldKey, 'highestEducation']} name={[name, 'highestEducation']} {...config.member_highestEducation}>
                          <Select placeholder='请选择' >
                            {curItem.isAlumnus === true && curItem.studentStatus && curItem.studentStatus === 3 ?
                              educationConf.filter(v => excultList.includes(v.id)).map(v => <Option key={v.id} value={v.id}>{v.name}</Option>) :
                              educationConf.map(v => <Option key={v.id} value={v.id}>{v.name}</Option>)}
                          </Select>
                        </FormItem>
                      </>}
                      {curItem.isAlumnus === true && <>
                        <FormItem {...formItemLayout} label='是否为创始人' fieldKey={[fieldKey, 'isFounder']} name={[name, 'isFounder']} {...config.member_isFounder}>
                          <Select placeholder='请选择' >
                            {isAlumni.map(v => <Option key={v.id} value={v.value}>{v.name}</Option>)}
                          </Select>
                        </FormItem>
                        <FormItem {...formItemLayout} label='公司任职' fieldKey={[fieldKey, 'takeOffice']} name={[name, 'takeOffice']} {...config.member_takeOffice} >
                          <Checkbox.Group options={plainOptions} onChange={(e) => this.handleChange(e, name, 'takeOffice')} />
                        </FormItem>
                        {curItem.takeOffice && curItem.takeOffice.includes(2) && <FormItem {...formItemLayout} label='职务' fieldKey={[fieldKey, 'job']} name={[name, 'job']} {...config.member_job}>
                          <Input placeholder='请输入职务' />
                        </FormItem>}
                        {curItem.takeOffice && curItem.takeOffice.includes(3) && <FormItem {...formItemLayout} label='占股比例' fieldKey={[fieldKey, 'ratio']} name={[name, 'ratio']} {...config.member_ratio}>
                          <Input style={{ width: 120 }} type="number" addonAfter="%" />
                        </FormItem>}
                      </>}
                    </>
                  }
                  <FormItem {...formItemLayoutWithoutLabel} >
                    <span style={{ color: '#1890ff', marginRight: '20px', cursor: 'pointer' }} onClick={() => add({}, name + 1)}><PlusCircleOutlined style={{ color: '#1890ff', fontSize: 18, marginRight: '5px' }} type="dashed" />添加</span>
                    {name > 0 && <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={() => remove(name)} ><MinusCircleOutlined style={{ color: '#1890ff', fontSize: 18, marginRight: '5px' }} />删除</span>}
                  </FormItem>
                </FormItem>
                {/* {fields.length > 1 && <MinusCircleOutlined style={{ fontSize: 24, cursor: 'pointer' }} onClick={() => remove(name)} />} */}
              </div>
            </div>
          )
        })}
        {/* <FormItem wrapperCol={{
                    xs: { span: 24, offset: 0 },
                    sm: { span: 12, offset: 11 },
                }} style={{ marginTop: '10px' }} >
                    <Button type="dashed" onClick={() => add()} >新增</Button>
                </FormItem> */}
      </div>
    )
  }
}

export default Investment;