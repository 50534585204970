import { curYear } from '../../utils/utils';
import { currencyType, patentTypeConf } from '../../utils/config'
// import moment from 'moment';

export const showKeyList = {
  basicInfo: [
    {
      name: 'logo', label: '项目logo', inputType: 'input', formatType: 'logo',
      otherProps: { action: '/api/file' },
    },
    {
      name: 'name',
      label: '项目名称',
      inputType: 'input',
      formatType: 'input',
    },
    {
      name: 'area',
      label: '公司或项目团队所在地',
      inputType: 'input',
      formatType: 'input',
    },
    {
      name: 'summary',
      label: '项目概述',
      inputType: 'input',
      formatType: 'input',
    },
    {
      name: 'pictures',
      label: '项目展示图片',
      inputType: 'input',
      formatType: 'listImg',
      otherProps: {
        action: '/api/file',
        urlKey: 'file_id',
      },
    },
    {
      name: 'videoUrl',
      label: '项目展示视频',
      inputType: 'input',
      formatType: 'website',
    },
    {
      name: 'ppt',
      label: '项目路演PPT',
      inputType: 'input',
      formatType: 'listFile',
      otherProps: {
        action: '/api/file',
        urlKey: 'file_id',
        nameKey: 'name',
      },
    }
  ],
  progress: [
    {
      label: '项目进展',
      name: 'progress',
      inputType: 'select',
      formatType: 'singleSelect',
      otherProps: {
        optionList: 'constant.prjStatusConf',
        labelKey: 'name',
        valueKey: 'id',
      },
      // rules: [{ required: true, message: '区域为必填项' }],
      compProps: {
        placeholder: '请选择',
        // disabled: true,
        allowClear: true,
      },
    },
    {
      name: 'unitName',
      label: '公司名称',
      inputType: 'input',
      formatType: 'input',
      visibleProps: {
        visibleKey: 'progress',
        visibleValue: 2,
        visibleWay: 'equal'
      }
    },
    {
      name: 'establishmentDate',
      label: '工商登记时间',
      inputType: 'input',
      formatType: 'input',
      visibleProps: {
        visibleKey: 'progress',
        visibleValue: 2,
        visibleWay: 'equal'
      }
    },
    {
      name: 'license',
      label: '营业执照',
      inputType: 'input',
      formatType: 'logo',
      otherProps: {
        action: '/api/file',
      },
      visibleProps: {
        visibleKey: 'progress',
        visibleValue: 2,
        visibleWay: 'equal'
      }
    },
    {
      name: 'website',
      label: '企业网址',
      inputType: 'input',
      formatType: 'website',
      visibleProps: {
        visibleKey: 'progress',
        visibleValue: 2,
        visibleWay: 'equal'
      }
    },
    {
      name: 'income',
      label: `${curYear - 1}年底营业收入`,
      inputType: 'input',
      formatType: 'input',
      visibleProps: {
        visibleKey: 'progress',
        visibleValue: 2,
        visibleWay: 'equal'
      }
    },
    {
      label: '获得投资情况',
      name: 'isFinance',
      inputType: 'select',
      formatType: 'singleSelect',
      otherProps: {
        optionList: 'constant.isFinance',
        labelKey: 'name',
        valueKey: 'value',
      },
      visibleProps: {
        visibleKey: 'progress',
        visibleValue: 2,
        visibleWay: 'equal'
      }
    },
    {
      name: 'financings',
      inputType: 'table',
      formatType: 'table',
      otherProps: {
        columns: [
          {
            title: '获得时间',
            dataIndex: 'getDate',
            key: 'getDate',
          },
          {
            title: '投资机构',
            dataIndex: 'investor',
            key: 'investor',
          },
          {
            title: '融资轮次',
            dataIndex: 'round',
            key: 'round',
          },
          {
            title: '融资金额',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, row) => {
              const unit = (currencyType.find(ele => String(ele.id) === String(row.amountType)) || {}).name || '元'
              return `${text || (text === 0 && 0) || '-'}${unit}`
            }
          },
        ],
        pagination: false
      },
      visibleProps: [{
        visibleKey: 'progress',
        visibleValue: 2,
        visibleWay: 'equal'
      }, {
        visibleKey: 'isFinance',
        visibleValue: true,
        visibleWay: 'equal'
      }]
    },
    {
      name: 'unitName',
      label: '社会组织名称',
      inputType: 'input',
      formatType: 'input',
      visibleProps: {
        visibleKey: 'progress',
        visibleValue: 3,
        visibleWay: 'equal'
      }
    },
    {
      label: '获得资金支持情况',
      name: 'isFinance',
      inputType: 'select',
      formatType: 'singleSelect',
      otherProps: {
        optionList: 'constant.supportConf',
        labelKey: 'name',
        valueKey: 'value',
      },
      visibleProps: {
        visibleKey: 'progress',
        visibleValue: 3,
        visibleWay: 'equal'
      }
    },
    {
      label: '是否已获专利',
      name: 'isPatent',
      inputType: 'select',
      formatType: 'singleSelect',
      otherProps: {
        optionList: 'constant.hasPatentConf',
        labelKey: 'name',
        valueKey: 'value',
      },
    },
    {
      name: 'patents',
      inputType: 'table',
      formatType: 'table',
      otherProps: {
        columns: [
          {
            title: '专利名称',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: '专利类型',
            dataIndex: 'type',
            key: 'type',
            render: text => (patentTypeConf.find(ele => String(ele.id) === String(text)) || {}).name || ''
          },
          {
            title: '专利号',
            dataIndex: 'applicationNo',
            key: 'applicationNo',
          },
          {
            title: '获得时间',
            dataIndex: 'publishedAt',
            key: 'publishedAt',
          },
        ],
        pagination: false,
        rowKey: 'applicationNo'
      },
      visibleProps: {
        visibleKey: 'isPatent',
        visibleValue: true,
        visibleWay: 'equal'
      }
    },
  ],
  team: [
    { name: 'name', label: '姓名', inputType: 'input', formatType: 'input' },
    {
      name: 'nationality',
      label: '国籍',
      inputType: 'input',
      formatType: 'input',
    },
    {
      name: 'contactNumber',
      label: '手机号码',
      inputType: 'input',
      formatType: 'input',
    },
    {
      name: 'email',
      label: '电子邮箱',
      inputType: 'input',
      formatType: 'input',
    },
    {
      label: '是否华中大教师',
      name: 'isTeacher',
      inputType: 'select',
      formatType: 'singleSelect',
      otherProps: {
        optionList: 'constant.isAlumni',
        labelKey: 'name',
        valueKey: 'value',
      },
    },
    {
      label: '是否校友',
      name: 'isAlumnus',
      inputType: 'select',
      formatType: 'singleSelect',
      otherProps: {
        optionList: 'constant.isAlumni',
        labelKey: 'name',
        valueKey: 'value',
      },
    },
    {
      label: '毕业情况',
      name: 'studentStatus',
      inputType: 'select',
      formatType: 'singleSelect',
      otherProps: {
        optionList: 'constant.studentStatusConf',
        labelKey: 'name',
        valueKey: 'id',
      },
      visibleProps: {
        visibleKey: 'isAlumnus',
        visibleValue: true,
        visibleWay: 'equal'
      }
    },
    {
      name: 'education',
      label: '在华科学历层次',
      inputType: 'input',
      formatType: 'input',
      visibleProps: {
        visibleKey: 'isAlumnus',
        visibleValue: true,
        visibleWay: 'equal'
      }
    },
    {
      name: 'faculty',
      label: '所在院系',
      inputType: 'input',
      formatType: 'input',
      visibleProps: {
        visibleKey: 'isAlumnus',
        visibleValue: true,
        visibleWay: 'equal'
      },
    },
    {
      name: 'major',
      label: '专业名称',
      inputType: 'input',
      formatType: 'input',
      visibleProps: {
        visibleKey: 'isAlumnus',
        visibleValue: true,
        visibleWay: 'equal'
      }
    },
    {
      name: 'tutor',
      label: '导师',
      inputType: 'input',
      formatType: 'input',
      visibleProps: [{
        visibleKey: 'isAlumnus',
        visibleValue: true,
        visibleWay: 'equal',
      }, {
        visibleKey: 'education',
        visibleValue: ['硕士在读', '硕士', '博士在读', '博士'],
        visibleWay: 'beInclude',
      }],
    },
    {
      name: 'enterDate',
      label: '入学年份',
      inputType: 'input',
      formatType: 'date',
      otherProps: {
        format: 'YYYY-MM'
      },
      visibleProps: {
        visibleKey: 'isAlumnus',
        visibleValue: true,
        visibleWay: 'equal'
      }
    },
    {
      name: 'highestUniversity',
      label: '最后在读/毕业学校名称',
      inputType: 'input',
      formatType: 'input',
      visibleProps: [[{
        visibleKey: 'studentStatus',
        visibleValue: 3,
        visibleWay: 'equal'
      }], [{
        visibleKey: 'isAlumnus',
        visibleValue: false,
        visibleWay: 'equal'
      }]]
    },
    {
      name: 'highestEducation',
      label: '最后在读/毕业学校层次',
      inputType: 'input',
      formatType: 'input',
      visibleProps: [[{
        visibleKey: 'studentStatus',
        visibleValue: 3,
        visibleWay: 'equal'
      }], [{
        visibleKey: 'isAlumnus',
        visibleValue: false,
        visibleWay: 'equal'
      }]]
    },
    {
      label: '是否为创始人',
      name: 'isFounder',
      inputType: 'select',
      formatType: 'singleSelect',
      otherProps: {
        optionList: 'constant.isAlumni',
        labelKey: 'name',
        valueKey: 'value',
      },
      visibleProps: {
        visibleKey: 'isAlumnus',
        visibleValue: true,
        visibleWay: 'equal'
      }
    },
    {
      label: '公司任职',
      name: 'takeOffice',
      inputType: 'select',
      formatType: 'select',
      otherProps: {
        optionList: 'constant.positionConf',
        labelKey: 'name',
        valueKey: 'id',
      },
      visibleProps: {
        visibleKey: 'isAlumnus',
        visibleValue: true,
        visibleWay: 'equal'
      }
    },
    {
      label: '职务',
      name: 'job',
      inputType: 'input',
      formatType: 'input',
      visibleProps: [{
        visibleKey: 'takeOffice',
        visibleValue: 2,
        visibleWay: 'include'
      }, {
        visibleKey: 'isAlumnus',
        visibleValue: true,
        visibleWay: 'equal'
      }]
    },
    {
      label: '占股比例',
      name: 'ratio',
      inputType: 'input',
      formatType: 'input',
      visibleProps: [{
        visibleKey: 'takeOffice',
        visibleValue: 3,
        visibleWay: 'include'
      }, {
        visibleKey: 'isAlumnus',
        visibleValue: true,
        visibleWay: 'equal'
      }]
    },
  ],
  matchInfo: [
    {
      label: '参赛组别',
      name: 'group',
      inputType: 'select',
      formatType: 'singleSelect',
      otherProps: {
        optionList: 'constant.groupTypeConf',
        labelKey: 'name',
        valueKey: 'id',
      },
    },
    {
      label: '参赛赛区',
      name: 'region',
      inputType: 'select',
      formatType: 'singleSelect',
      otherProps: {
        optionList: 'constant.regionConf',
        labelKey: 'name',
        valueKey: 'id',
      },
    },
    {
      name: 'alumniAssociation',
      label: '校友会',
      inputType: 'input',
      formatType: 'input',
    },
    {
      label: '项目领域',
      name: 'field',
      inputType: 'select',
      formatType: 'select',
      otherProps: {
        optionList: 'constant.fieldConf',
        labelKey: 'name',
        valueKey: 'id',
      },
    },
    {
      label: '隐私设置',
      name: 'isPrivacy',
      inputType: 'select',
      formatType: 'singleSelect',
      otherProps: {
        optionList: 'constant.isPrivacyConf',
        labelKey: 'name',
        valueKey: 'value',
      },
    },
  ]
}