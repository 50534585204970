import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Pagination } from 'antd'
import { getList } from '../../store/action/tutor'
import { fileUrl } from '../../utils/config'
import { Link } from 'react-router-dom';
import './index.less'

const ellipsis_2 = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical'
}

export const TutorCard = ({ data, showModal, onClick }) => {
  const [visible, setVisible] = useState(false)
  return (
    <div className="tutorCard"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      style={!showModal ? { cursor: 'default' } : null}
      onClick={() => onClick ? onClick(data.tutor_id) : null}
    >
      <div className='avator'>
        <img src={`${fileUrl}/${data.img}`} alt="logo" />
      </div>
      <div className="content-body">
        <p className="name" style={showModal && visible ? { visibility: 'hidden' } : null}>{data.name}</p>
        <p className="intro" style={showModal ? visible ? { visibility: 'hidden' } : ellipsis_2 : null}>{data.intro}</p>
        {showModal && visible && <div className="modal" >
          <p className="modal-name">{data.name}</p>
          <p className="modal-intro">{data.intro}</p>
          <Link className="modal-button" onClick={e => e.stopPropagation()} to={`/tutor/detail/${data.tutor_id}`}>{'阅读详情>'}</Link>
        </div>}
      </div>
    </div >
  )
}

const Tutor = (props) => {
  const { searchParams, count, dispatch, list, history } = props;
  useEffect(() => {
    getList(searchParams, dispatch)
  }, [])

  const handleChange = (e) => {
    // window.scrollTo(0, 0)
    getList({ ...searchParams, page: e }, dispatch)
  }

  return (
    <div>
      {list.length === 0 && <div className="defaultImg" style={{ backgroundImage: `url('/tutor.png')` }}></div>}
      <div className='tutor'>
        <div className='contentArea'>
          {list.map(v =>
            <div className="totur-card"
              key={v.tutor_id}
            >
              <TutorCard showModal data={v} onClick={(id) => history.push(`/tutor/detail/${id}`)} />
            </div>
          )}
        </div>
        {list.length > 0 && <Pagination showQuickJumper current={searchParams.page} defaultPageSize={12} total={count} onChange={handleChange} />}
      </div>
    </div>
  )
}

export default withRouter(connect(state => state.tutor)(Tutor))