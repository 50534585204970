import React, { Component } from 'react';
import { connect } from "react-redux";
import { getRegistrationDetail, getSession } from '../../store/action/home'
import { showKeyList } from './config'
import { constant, currencyType } from '../../utils/config';
import showFormat from '../../utils/fieldShowDataFormat'
import { handleFieldsVisible } from '../../utils/utils'
import './index.less'

@connect((state) => state)
class RegisterDetail extends Component {
  componentDidMount() {
    const { dispatch, home, history } = this.props;
    const { user } = home || {}
    if (!user) {
      getSession(dispatch).then(res => {
        if (!res) {
          history.push('/home')
        }
      })
    }
    getRegistrationDetail(dispatch)
  }

  renderDataList = (fieldProps, data) => {
    const chooseList = { constant }
    return (fieldProps || []).map(ele => {
      const { label, name, formatType, otherProps, visibleProps } = ele;
      let visible = true;
      if (visibleProps) {
        visible = handleFieldsVisible(data, {}, visibleProps)
      }
      if (!visible) return null
      const prop = { ...(otherProps || {}) }
      if (otherProps && otherProps.optionList && typeof otherProps.optionList === 'string') {
        const chooseArr = otherProps.optionList.split('.');
        prop.optionList =
          (chooseList[chooseArr[0]] && chooseList[chooseArr[0]][chooseArr[1] || '']) || [];
      }
      const value = data && data[name];
      const showFunc = showFormat[formatType];
      const showValue = showFunc ? showFunc(value, prop) : null;
      return (
        <div className="fieldItem" key={name}>
          <div className="fieldLabel">{(label && `${label}`) || ''}</div>
          <div className="fieldValue">{showValue}</div>
        </div>
      );
    })
  }

  goBack = () => {
    const { history } = this.props;
    history.push('/personal')
  }

  render() {
    const { home } = this.props;
    const detail = home.registrationDetail || {}
    const unit = (currencyType.find(ele => String(ele.id) === String(detail.incomeType || '')) || {}).name
    detail.income = `${detail.income || (detail.income === 0 && 0) || '-'}${unit || '元'}`;
    detail.teams = (detail.teams || []).map(ele => {
      const item = { ...ele }
      item.ratio = item.ratio ? `${item.ratio}%` : (item.ratio === 0 && 0) || '';
      return item
    })
    // console.log({ detail })
    return (
      <div className="registerDetail">
        <div className="infoArea">
          <div className="navigationArea">
            <div className='bread'><span className="backList" onClick={this.goBack}>我的报名</span> {'>'} <span className="detail">查看详情</span></div>
            <div className='button' onClick={this.goBack}>返回</div>
          </div>
          <div className="cardTitle">项目基本信息</div>
          <div className="cardArea">
            {this.renderDataList(showKeyList.basicInfo, detail)}
          </div>
          <div className="cardTitle">项目进展</div>
          <div className="cardArea">
            {this.renderDataList(showKeyList.progress, detail)}
          </div>
          <div className="cardTitle">团队成员</div>
          <div className="cardArea">
            {(detail.teams || []).map((ele, index) => (
              <div key={index.toString()}>
                <div className="teamItem">成员{index + 1}</div>
                <div>
                  {this.renderDataList(showKeyList.team, ele)}
                </div>
                {(index < detail.teams.length - 1 && (
                  <div className="divider" style={{ marginBottom: '30px' }} />
                )) || null}
              </div>
            ))}
          </div>
          <div className="cardTitle">参赛信息</div>
          <div className="cardArea">
            {this.renderDataList(showKeyList.matchInfo, detail)}
          </div>
        </div>
      </div>
    )
  }
}

export default RegisterDetail
