import React, { Component } from 'react';
import { Upload, Button, message, Spin } from 'antd'
import { UploadOutlined, PaperClipOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { formatFileList, urlPre } from '../utils/utils'
import { withRouter } from 'react-router';
import download from 'ly-downloader';

const pptTypeList = ['ppt', 'pptx', 'pot', 'potx', 'pps', 'ppsx', 'pptm', 'potm']
@withRouter
class UploadCom extends Component {
	constructor(props) {
		super(props)
		this.state = {
			fileList: []
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (props.value && props.value.length > 0 && state.fileList.length === 0) {
			return {
				fileList: formatFileList(props.value)
			}
		}
		return null
	}

	beforeUpload = (file) => {
		const fileArr = file.name.split('.');
		const fileType = fileArr[fileArr.length - 1]
		const isPPT = pptTypeList.includes(fileType);
		if (!isPPT) {
			message.error('只能上传 ppt/pptx/pot/potx/pps/ppsx/pptm/potm 文件!');
		}
		const isLt50M = file.size / 1024 / 1024 < 50;
		if (!isLt50M) {
			message.error('最大不超过 50MB!');
		}
		// isJpgOrPng && 
		return isPPT && isLt50M;
	}
	handleChange = (info) => {
		const { onChange, history } = this.props;
		if (!info.file.status) return;
		if (info.file.status !== 'uploading') {
			const initFileList = info.fileList.map(v => {
				if (v.response) {
					if (v.response.code === 205) {
						message.warning('登录状态已过期')
						setTimeout(() => {
							history.push('/home')
						}, 1500);
						return {};
					}
					return {
						name: v.name,
						file_id: v.response.data.file_id,
					}

				}
				const urlArr = v.url ? v.url.split(`${urlPre}/`) : []
				const url = urlArr.length > 0 ? urlArr[1] : ''
				return {
					name: v.name,
					file_id: url
				}
			})
			onChange(initFileList)
		}
		this.setState({
			fileList: info.fileList
		})
	};

	downLoadFile = (val) => {
		// download={v.fileName} key={v.file_id} href={`${fileUrl}/${v.file_id}`}
		const ua = navigator.userAgent.toLowerCase();
		if (ua.includes('micromessenger')) {
			message.warning('当前浏览器暂不支持下载附件，请用其它浏览器打开')
		} else {
			download(1, val.url, val.name)
		}
	}

	render() {
		const { fileList } = this.state;
		const initProps = {
			action: "/api/file/upload",
			onChange: this.handleChange,
			beforeUpload: this.beforeUpload,
			fileList,
			// accept: '.ppt, .pptx, .potx, .pps, ppsx, ppsx ,pptm ,potm',
			maxCount: 1,
			showUploadList: { showDownloadIcon: true },
			itemRender: (_, file, fileList, actions) => {
				// console.log(fileList)
				return <div>{fileList.map(v =>
					<div key={v.url} style={{ marginTop: '8px', height: '22px', lineHeight: '22px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						{v.status === 'uploading' ? <Spin indicator={<LoadingOutlined spin />} /> : <PaperClipOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />}
						<span style={{ flex: 'auto', padding: '0 5px', color: '#1890FF', cursor: 'pointer' }} key={v.url} onClick={() => this.downLoadFile(v)}>{v.name}</span>
						<DeleteOutlined style={{ color: 'rgba(0, 0, 0, 0.45)', cursor: 'pointer' }} onClick={actions.remove} />
					</div>)}
				</div>
			}
		}
		return (
			<div>
				<Upload
					{...initProps}
				>
					<Button icon={<UploadOutlined />}>上传</Button>
				</Upload >
			</div>

		)
	}
}

export default UploadCom;