import React, { useState, useEffect } from 'react';
import { Menu, Card, Pagination } from 'antd'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import qs from 'qs'
import { convertToHtml, getAllText } from '../../utils/utils'
import { getList } from '../../store/action/news'
import { fileUrl } from '../../utils/config'

import './index.less';

const { SubMenu, Item: MenuItem } = Menu

const menuList = [
  { label: '大赛资讯', value: 'news' },
  { label: '通知公告', value: 'notice' },
  { label: '双创动态', value: 'dynamic' },
  {
    label: '赛区新闻', value: 'conferneceNews',
    children: [
      { label: '东部赛区', value: 'east' },
      { label: '南部赛区', value: 'south' },
      { label: '西部赛区', value: 'west' },
      { label: '北部赛区', value: 'north' },
      { label: '中部赛区', value: 'center' },
      { label: '海外赛区', value: 'overseas' }
    ]
  },
]

const urlConf = {
  east: '/east_region.png',
  south: '/south_region.png',
  west: '/west_region.png',
  north: '/north_region.png',
  center: '/center_region.png',
  overseas: '/overseas_region.png'
}

const normalStyle = {
  height: 52,
  lineHeight: '52px'
}

const NewsCard = ({ onClick, data }) => (
  <Card style={{ marginBottom: '20px' }}>
    <div className="newsCard" onClick={() => onClick(data.news_id)}>
      <div className="leftContent" >
        <h3>{data.title}</h3>
        <p className="detail">{getAllText(convertToHtml(data.detail))}</p>
        <p className="date">{data.issueDate}</p>
      </div>
      {!data.video && <img src={data.img ? `${fileUrl}/${data.img}` : '/bg_default.png'} alt='缩略图' />}
      {data.video && <video style={{
        // backgroundImage: `url('/bg_image.png')`,
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: '100% 100%'
      }} src={`${fileUrl}/${data.video}`} controls poster={data.img ? `${fileUrl}/${data.img}` : ''}></video>}
    </div>
  </Card>
)

const News = (props) => {
  const { dispatch, location } = props;
  const [menuKey, setMenuKey] = useState('news')
  const keyConf = menuList.find(v => v.value === 'conferneceNews').children.map(x => x.value)
  useEffect(() => {
    const urlArr = location.search.split('?')
    let curKey = menuKey
    if (urlArr.length > 1) {
      const { type } = qs.parse(urlArr[1]) || {}
      curKey = type;
      setMenuKey(curKey)
    }
    if (menuKey) {
      getList(props[`${curKey}SearchParams`], dispatch)
    }
  }, [])

  const menuClick = (menu) => {
    const { key } = menu
    setMenuKey(key)
    getList(props[`${key}SearchParams`], dispatch)
    window.scrollTo(0, 0)
  }

  const toDetail = (id) => {
    window.open(`/dynamic/detail/${id}`)
  }

  const handleChange = (e) => {
    getList({ ...props[`${menuKey}SearchParams`], page: e }, dispatch)
  }

  return (
    <div className="news">
      <div className="menu">
        <Menu
          selectedKeys={[menuKey]}
          mode="inline"
          onClick={menuClick}
        >
          {menuList.map(v => {
            if (!v.children) {
              return <MenuItem style={normalStyle} key={v.value}>{v.label}</MenuItem>
            }
            return (
              <SubMenu key={v.value} title={v.label} className={v.children.findIndex(z => z.value === menuKey) > -1 ? 'curItem' : null} >
                {v.children.map(x => <MenuItem style={menuKey === x.value ? { ...normalStyle, backgroundColor: 'rgba(6,63,198,0.2)', color: '#063FC6' } : normalStyle} key={x.value}>{x.label}</MenuItem>)}
              </SubMenu>)
          })}
        </Menu>
      </div>

      <div className="content">
        {keyConf.includes(menuKey) && props[`${menuKey}List`].length === 0 && <div className="defalut_img"><img src={urlConf[menuKey]} alt='默认图片' /></div>}
        {props[`${menuKey}List`].length > 0 &&
          <>
            <div className="contentList">
              {props[`${menuKey}List`].map(v => <NewsCard key={v.news_id} data={v} onClick={toDetail} />)}
            </div>
            <Pagination
              showQuickJumper
              current={props[`${menuKey}SearchParams`].page}
              defaultPageSize={5}
              total={props[`${menuKey}Count`]}
              onChange={handleChange}
            />
          </>}
      </div>
    </div>
  )
}

export default withRouter(connect(state => state.news)(News))