import React, { Component } from 'react'
import { Menu, Card, Breadcrumb } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { newsBlocksDynamic, } from './config'
import { getAllText } from '../../utils/utils'
import { getList } from '../../store/action/news'
import './index.less'

const NewsCard = ({ data, onClick }) => {
  return (
    <Card className="news-cardnew" style={{ cursor: 'pointer' }} onClick={onClick}>
      <h2 className="card-title">{data.title}</h2>
      <div className="detail">{getAllText(data.detail)}</div>
      <div>{data.issueDate}</div>
    </Card>
  )
}

@withRouter
@connect(state => state)
class Dynamic extends Component {
  constructor(props) {
    super(props)
    const { match: {
      params: { type }
    } } = props;
    this.state = {
      type
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    getList({ page: 1, pageSize: 2 }, dispatch)
  }

  toNewsDetail = id => {
    const { history } = this.props;
    // history.push(`/home/detail/${id}`)
    window.open(`/home/detail/${id}`)
  }

  menuClick = (e) => {
    const { history } = this.props;
    this.setState({
      type: e.key
    })
    history.push(`/home/dynamic/${e.key}`)
  }

  render() {
    const { type } = this.state;
    console.log(this.props)
    const { news: { list } } = this.props;
    const listConf = {
      dynamic: list.filter(v => v.title !== '华中科技大学70周年校庆公告（第一号）'),
      notice: list.filter(v => v.title === '华中科技大学70周年校庆公告（第一号）')
    }
    return (
      <div className="home-dynamic">
        <Breadcrumb style={{ marginBottom: '20px', paddingLeft: '10px' }}>
          <Breadcrumb.Item><a href="/home">首页</a></Breadcrumb.Item>
          <Breadcrumb.Item>更多 </Breadcrumb.Item>
        </Breadcrumb>
        <div className="content">
          <div className="d_menu">
            <Menu
              selectedKeys={type}
              onClick={this.menuClick}
            >
              {newsBlocksDynamic.map(v => <Menu.Item style={{ height: 52, lineHeight: '52px' }} key={v.value}>{v.label}</Menu.Item>)}
            </Menu>
          </div>
          <div className="d_content">
            <div>
              {type !== 'news' ?
                <div>
                  {type === 'notice' &&
                    <div style={{ marginBottom: '20px' }}>
                      {/* <NewsCard data={hustAbout} onClick={() => this.toNewsDetail(hustAbout.news_id)} /> */}
                    </div>}
                  {listConf[type].map(v => <div key={v.news_id} style={{ marginBottom: '20px' }}>
                    <NewsCard data={v} onClick={() => this.toNewsDetail(v.news_id)} />
                  </div>)}
                </div> : null
                // xiaozhangAbout.map(ele => (
                //   <div key={ele.news_id}>
                //     <div className="video1">
                //       {/* <video id='video' src="/video/%E5%85%A8%E7%90%83%E6%A0%A1%E5%8F%8B%E5%88%9B%E6%96%B0%E5%88%9B%E4%B8%9A%E5%A4%A7%E8%B5%9B.mp4" controls ></video> */}
                //       <video id='video' src={ele.videoUrl}controls ></video>
                //     </div>
                //     <NewsCard data={ele} onClick={() => this.toNewsDetail(ele.news_id)} />
                //   </div>
                // ))
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Dynamic