
export const newsBlocks = [
  {
    label: '双创动态',
    value: 'dynamic',
    style: { width: 350 }
  },
  {
    label: '大赛资讯',
    value: 'news',
    style: { flex: 1, margin: '0 40px' }
  },
  {
    label: '通知公告',
    value: 'notice',
    style: { width: 350 }
  },
]

export const newsBlocksDynamic = [
  {
    label: '大赛资讯',
    value: 'news',
    style: { flex: 1, margin: '0 40px' }
  },
  {
    label: '通知公告',
    value: 'notice',
    style: { width: 350 }
  },
  {
    label: '双创动态',
    value: 'dynamic',
    style: { width: 350 }
  },
]