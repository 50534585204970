import constant from "../constant/home";
const initState = {
  dynamicList: [],
  newsList: [],
  noticeList: [],
  searchParams: {},
  registrationDetail: {},
  user: {},
  curUser: {},
  colleges: []
};
const home = (state = initState, action) => {
  switch (action.type) {
    case constant.GETSESSION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default home;
