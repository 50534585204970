import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { CheckCircleFilled } from '@ant-design/icons';
import { groupTypeConf, regionConf, fieldConf } from '../../utils/config'
import { findNameById } from './utils'
import './index.less'

const MyRegister = (props) => {
  const { home, history } = props;
  const [detail, setDetail] = useState({})
  
  useEffect(() => {
    if (home && home.registrationDetail) {
      setDetail(home.registrationDetail)
    }
  }, [home])

  const onClickButton = (key) => {
    const urlConfig = {
      edit: '/enter',
      detail: '/registerInfo'
    }
    const url = urlConfig[key]
    if (url) {
      history.push(url)
    }
  }

  const showField = {
    group: { label: '参赛组别', value: findNameById(detail.group, groupTypeConf)},
    field: { label: '项目领域', value: (detail.field || []).map(ele => findNameById(ele, fieldConf)).filter(ele => !!ele).join('，')},
    region: { label: '参赛赛区', value: findNameById(detail.region, regionConf)},
    registDate: { label: '报名时间', value: detail.registDate? moment(detail.registDate).format('YYYY-MM-DD HH:mm') : ''},
  }

  return detail.registDate? (
    <div className="myRegister">
      <div className="title">{detail.name}</div>
      <div className="info">
        <div className="detailInfo">
          {Object.keys(showField).map(key => {
            const target = showField[key];
            return (
              <div className="detailItem" key={key}>
                <span className="detailLabel">{target.label}：</span>
                <span className="detailValue">{target.value}</span>
              </div>
            )
          })}
        </div>
        <div className='succuss'>
          <CheckCircleFilled className="icon" />
          报名成功
        </div>
      </div>
      <div className="divider" />
      <div className="foot">
        <div className="buttonArea">
          <div className="button" onClick={() => onClickButton('detail')}>查看详情</div>
          <div className="button" onClick={() => onClickButton('edit')}>修改报名信息</div>
        </div>
        {/* <div className="qqInfo">大赛QQ群：788580901</div> */}
      </div>
    </div>
  ) : null
}

export default MyRegister