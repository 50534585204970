import constant from '../constant/news'
import api from '../../graphql/news'
import { get } from '../../utils/require'

const newTypeConfig = {
  1: 'intro',
  2: 'dynamic',
  3: 'news',
  4: 'notice',
  5: 'conferneceNews'
}
const newsZoneConf = {
  1: 'east',
  2: 'south',
  3: 'west',
  4: 'north',
  5: 'center',
  6: 'overseas'
}

export const getDetail = async (id, dispatch) => {
  const res = await get(`/api/news/detail?news_id=${id}`)
  if (res) {
    dispatch({
      type: constant.NEWS_DETAIL,
      payload: { detail: res }
    })
  }
}

export const getList = async (params, dispatch) => {
  const { type, zone } = params;
  const typePre = type === 5 ? newsZoneConf[zone] : newTypeConfig[type]
  const res = await get('/api/news/list', params)
  if (res) {
    const news = res || {}
    const otherProps = {}
    if (type === 1) {
      otherProps.detail = news.rows && news.rows.length >= 0 ? news.rows[0] : {}
    }
    dispatch({
      type: constant.NEWS_DETAIL,
      payload: {
        [`${typePre}SearchParams`]: params,
        [`${typePre}List`]: news.rows || [],
        [`${typePre}Count`]: news.count || 0,
        ...otherProps
      }
    })
  }
}
