import country from './country.json'
import xuanyuanJSON from './xuanyuan.json'
import xyhJSON from './xyh.json'
import shuoshiJSON from './shuoshi.json'
import boshiJSON from './boshi.json'
import { formatTreeData } from './utils'
import moment from 'moment'

// 项目进展
export const prjStatusConf = [
  {
    id: 1,
    name: "创意计划阶段",
  },
  {
    id: 2,
    name: "已注册公司运营",
  },
  {
    id: 3,
    name: "已注册社会组织",
  },
];

// 是否已获专利
export const hasPatentConf = [
  {
    id: 1,
    name: "是",
    value: true,
  },
  {
    id: 2,
    name: "否",
    value: false,
  },
];

// 获得投资情况
export const investConf = [
  {
    id: 1,
    name: "已获融资",
    value: true,
  },
  {
    id: 2,
    name: "未获融资",
    value: false,
  },
];

// 专利类别
export const patentTypeConf = [
  {
    id: 1,
    name: "发明专利",
  },
  {
    id: 2,
    name: "实用新型专利",
  },
  {
    id: 3,
    name: "外观设计专利",
  },
  {
    id: 4,
    name: "其他",
  },
];

// 获得资金支持情况
export const supportConf = [
  {
    id: 1,
    name: "已获投资",
    value: true,
  },
  {
    id: 2,
    name: "未获投资",
    value: false,
  },
];

// 获得资金支持情况
export const isFinance = [
  {
    id: 1,
    name: "已获融资",
    value: true,
  },
  {
    id: 2,
    name: "未获融资",
    value: false,
  },
];

// 是否校友
export const isAlumni = [
  {
    id: 1,
    name: "是",
    value: true,
  },
  {
    id: 2,
    name: "否",
    value: false,
  },
];

export const currencyType = [
  {
    id: 1,
    name: "元人民币",
  },
  {
    id: 2,
    name: "万人民币",
  },
  {
    id: 3,
    name: "亿人民币",
  },
  {
    id: 4,
    name: "美元",
  },
  {
    id: 5,
    name: "万美元",
  },
  {
    id: 6,
    name: "亿美元",
  },
];

export const studentStatusConf = [
  {
    id: 1,
    name: "华中科技大学在校",
  },
  {
    id: 2,
    name: "华中科技大学毕业",
  },
  {
    id: 3,
    name: "华中科技大学毕业且目前在其他学校在校/毕业",
  },
];

// 最高学历
export const educationConf = [{
  id: '本科在读',
  name: "本科在读",
}, {
  id: '本科',
  name: "本科",
}, {
  id: '硕士在读',
  name: "硕士在读",
}, {
  id: '硕士',
  name: "硕士",
}, {
  id: '博士在读',
  name: "博士在读",
}, {
  id: '博士',
  name: "博士",
},
  // {
  //   id: '大专',
  //   name: "大专",
  // }
]

// 公司职位   
export const positionConf = [{
  id: 1,
  name: "法定代表人",
}, {
  id: 2,
  name: "高管",
}, {
  id: 3,
  name: "股东",
}, {
  id: 4,
  name: "其他",
}]

// 证件类型
export const cardType = [
  {
    id: 1,
    name: "居民身份证",
  },
  {
    id: 2,
    name: "香港永久性居民身份证",
  },
  {
    id: 3,
    name: "台湾居民来往大陆通行证",
  },
  {
    id: 4,
    name: "华侨身份证/护照（无身份证者可填护照号）",
  },
  {
    id: 5,
    name: "其他",
  },
];

// 参赛组别
export const groupTypeConf = [{
  id: 1,
  name: "创意组",
  extra: '参赛项目具有较好的创意和初步成型的产品原型或服务模式，在2021年10月28日前尚未完成工商登记注册。'
}, {
  id: 2,
  name: "初创组",
  extra: '参赛项目工商登记注册未满3年（2018年10月28日（含）之后注册），且获机构或个人股权投资累计不超过1000万元，年营收不超过1000万元人民币。'
}, {
  id: 3,
  name: "成长组",
  extra: '参赛项目工商登记注册3年以上（2018年10月28日之前注册），或工商登记注册未满3年，获机构或个人股权投资累计超过1000万元，年营收超1000万元人民币。'
}]

// 赛区
export const regionConf = [{
  id: 1,
  name: "东部赛区",
}, {
  id: 2,
  name: "南部赛区",
}, {
  id: 3,
  name: "西部赛区",
}, {
  id: 4,
  name: "北部赛区",
}, {
  id: 5,
  name: "中部赛区",
}, {
  id: 6,
  name: "海外赛区",
}]

// 项目领域
export const fieldConf = [{
  id: 1,
  name: "大数据",
}, {
  id: 2,
  name: "人工智能",
}, {
  id: 3,
  name: "芯片",
}, {
  id: 4,
  name: "互联网+",
}, {
  id: 5,
  name: "健康医疗",
}, {
  id: 6,
  name: "新材料",
}, {
  id: 7,
  name: "先进制造",
}, {
  id: 8,
  name: "新能源",
}, {
  id: 9,
  name: "文化创意",
}, {
  id: 10,
  name: "其他",
}]

export const isPrivacyConf = [
  {
    id: 1,
    name: "公开",
    value: false,
    extra: '向投资人展示项目的往届获奖信息、项目概述、团队成员、融资情况、专利情况、 工商注册信息'
  }, {
    id: 2,
    name: "保密",
    value: true,
    extra: '向投资人只展示项目基本信息'
  }
]

export const countryTel = country.map(ele => ({
  id: ele.tel,
  name: `${ele.name}+${ele.tel}`,
  label: ele.name,
}))

export const bkConf = formatTreeData(xuanyuanJSON, 'major', 'faculty')

export const ssConf = formatTreeData(shuoshiJSON, 'major', 'faculty')

export const bsConf = formatTreeData(boshiJSON, 'major', 'faculty')

export const AFTERTODAY = current => current && current > moment().endOf('day');

export const xyhConf = formatTreeData(xyhJSON, 'xyh', 'sq')
export const fileUrl = '/api/file';
export const constant = {
  prjStatusConf,
  hasPatentConf,
  investConf,
  patentTypeConf,
  supportConf,
  isAlumni,
  currencyType,
  studentStatusConf,
  educationConf,
  positionConf,
  cardType,
  groupTypeConf,
  regionConf,
  fieldConf,
  isPrivacyConf,
  countryTel,
  bkConf,
  ssConf,
  bsConf,
  isFinance,
}
