import { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Breadcrumb } from 'antd'
import { TutorCard } from './index'
import { getDetail } from "../../store/action/tutor";
import { convertToHtml } from '../../utils/utils'
import { fileUrl } from '../../utils/config'
import { Link } from "react-router-dom";

const TutorDetail = (props) => {
  const { dispatch, match: { params: { id } }, detail } = props
  useEffect(() => {
    getDetail(id, dispatch)
  }, [])
  return (
    <div className="tutor-detail">
      <Breadcrumb style={{ paddingBottom: '20px', borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
        <Breadcrumb.Item><Link to="/tutor">大赛导师</Link></Breadcrumb.Item>
        <Breadcrumb.Item>导师详情</Breadcrumb.Item>
      </Breadcrumb>
      <div className="tutor-content">
        <div className="content-card">
          <TutorCard data={detail} />
        </div>
        <div className="content-detail">
          {detail.video && <div className="detail-video">
            <video style={{
              // backgroundImage: `url('/bg_image.png')`,
              // backgroundRepeat: 'no-repeat',
              // backgroundSize: '100% 100%'
            }} src={`${fileUrl}/${detail.video}`} controls></video>
          </div>}
          <div className="detail-text" dangerouslySetInnerHTML={{ __html: convertToHtml(detail.detail) }}></div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(connect(state => state.tutor)(TutorDetail));