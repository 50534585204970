import moment from 'moment';
import React from 'react';
import { Image, Table } from 'antd';

const insertStr = (source, start, str) => source.splice(0, start) + str + source.splice(start);
const formatObj = {
  input: (v) => v,
  singleSelect: (v, { optionList = [], labelKey = 'label', valueKey = 'value' }) => {
    const cur = optionList.find((x) => String(x[valueKey]) === String(v)) || {};
    return cur[labelKey] || '-';
  },
  select: (v, { optionList = [], labelKey = 'label', valueKey = 'value', joinKey = ';' }) => {
    const curList = (v || []).map((cur) => {
      const curItem = optionList.find((x) => x[valueKey] == cur);
      return (curItem && curItem[labelKey]) || '';
    }, []);
    return curList.join(joinKey);
  },
  date: (v, { format }) => (v ? moment(v).format(format || 'YYYY-MM-DD') : ''),
  textarea: (v) => v,
  website: (v) => (
    <a
      href={v && v.startsWith('http') ? v : `http://${v}`}
      target="_black"
      style={{ textDecoration: 'underline', wordBreak: 'break-word' }}
    >
      {v}
    </a>
  ),
  multipleDate: (v) => v.join(' - ') || '-',
  mobilePhone: (v) => insertStr(insertStr(v, 3, '-'), 8, '-'),
  dateValue: (v) => (v ? moment(v).valueOf() : null),
  arrToStr: (v = [], { joinKey = '；', labelKey }) => {
    return labelKey ? v && v.map((x) => x[labelKey]).join(joinKey) : v && v.join(joinKey);
  },
  inputUnit: (v, { unit }) => {
    let num = v;
    if (num && unit === '万') {
      num = Number(v) / 10000;
    }
    return num;
  },
  html: (v) => <div dangerouslySetInnerHTML={{ __html: v }}></div>,
  logo: (v, { action, width, height }) => {
    if (v && action) {
      const url = `${action}/${v}`;
      return <Image width={width || 72} height={height || 72} src={url} />;
    }
    return '';
  },
  listImg: (v, { action, width, height, urlKey }) =>
    <div style={{ display: 'flex', flexWrap: 'warp' }}>
      <Image.PreviewGroup>
        {
          (v || []).map((ele) => {
            if (action) {
              const id = typeof ele === 'string' ? ele : ele[urlKey || 'file_id'];
              const url = `${action}/${id}`;
              return (
                <div key={id} style={{ width: width || 72, height: height || 72, marginBottom: '8px', marginRight: '8px' }}>
                  <Image
                    width={width || 72}
                    height={height || 72}
                    src={url}
                  />
                </div>
              );
            }
            return null;
          })
        }
      </Image.PreviewGroup>
    </div>,
  listFile: (v, { action, urlKey, nameKey }) =>
    (v || []).map((ele) => {
      const isString = typeof ele === 'string';
      if (action) {
        const url = `${action}/${isString ? ele : ele[urlKey || 'file_id']}`;
        const name = isString ? ele : ele[nameKey || 'name'];
        return (
          <div key={url}>
            <a href={url} download={name}>
              {name}
            </a>
          </div>
        );
      }
      return isString ? ele : ele[nameKey || 'name'] || '';
    }),
  table: (v, props) => (
    <div><Table dataSource={v || []} {...props} /></div>
  )
};

export default formatObj;
