import { stringify } from "qs";

export const postData = (params) => {
  return fetch("/graphql", {
    headers: {
      "content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(params),
  }).then((res) => res.json());
};

// export const getData = (params) => {
//     return fetch('/graphql');
// }

export const post = (url, data) => {
  return fetch(url, {
    headers: {
      "content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(data),
  }).then((res) => res.json());
}

export const get = (url, data) => {
  let api = url
  if (data) {
    api = `${url}?${stringify(data)}`
  }
  return fetch(api, {
    method: "GET",
  }).then((res) => res.json());
}
