import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Select, Checkbox, message, Tooltip } from "antd"
import { CheckCircleFilled } from '@ant-design/icons';
import { getSession } from "../../store/action/home";
import { countryTel, cardType } from '../../utils/config'
import { getUrlSearchObj } from '../../utils/utils';
import GlobalTel from '../../components/EditComponent/GlobalTel'
import { post, get } from '../../utils/require'
import './index.less'

const FormItem = Form.Item
const { Option } = Select

const noNameLayout = { wrapperCol: { span: 12 }, labelCol: { span: 12 } }
const noNameTailLayout = { wrapperCol: {xl: { span: 12, offset: 12 }, sm: {span: 24}} }
const normalLayout = { wrapperCol: { span: 8 }, labelCol: { span: 8 } }
const tailFormItemLayout = { wrapperCol: {xl: { span: 8, offset: 8 }, sm: {span: 24}} }

const pageConfig = {
  login: { title: '用户登录', buttonName: '登录', layout: noNameLayout, buttonLayout: noNameTailLayout, postUrl: '/api/user/login', jumpUrl: '/home' },
  register: { title: '用户注册', buttonName: '注册', layout: normalLayout, buttonLayout: tailFormItemLayout, postUrl: '/api/user/register', jumpUrl: '/home' },
  password: { title: '重置密码', buttonName: '重置密码', layout: normalLayout, buttonLayout: tailFormItemLayout, postUrl: '/api/user/resetPwd' },
}

const emailRules = { type: 'email', message: '请填写正确的邮箱地址' }
const passwordRules = {
  // pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()-+=]+$)(?![0-9\\W_!@#$%^&*`~()-+=]+$)(?![a-zA-Z0-9]+$)(?![a-zA-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9\\W_!@#$%^&*`~()-+=]+$)(?![0-9A-Z\\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()-+=]{8,20}$/,
  pattern: /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,20}/,
  message: '请输入8~20位包含大小写字母、数字和特殊符号的密码'
}

@connect((state) => state)
class Login extends Component {
  constructor(props) {
    super(props);
    const { location: { search } } = props;
    const queryParams = getUrlSearchObj(search || '')
    this.state = {
      pageType: queryParams.type || 'login',
      loading: false,
      querySuccess: false,
      suffixText: '发送验证码',
      flag: 'active',
      count: 60,
      captcha: '',
    }
  }

  formRef = createRef()
  
  componentDidMount() {
    const { dispatch } = this.props;
    getSession(dispatch);
    if (this.formRef && this.formRef.current) {
      this.formRef.current.resetFields()
    }
    this.getCaptcha()
  }

  getCaptcha = () => {
    const url = `/api/user/imgCaptcha`
      get(url).then((res) => {
        console.log({res})
        const { code, captcha } = res || {}
        if (code === 200 && captcha) {
          this.setState({ captcha })          
        }
      })
  }

  goPage = url => {
    const { history } = this.props;
    history.push(url)
  }

  changePage = key => {
    const url = `/login?type=${key}`
    this.setState({
      pageType: key,
      loading: false,
      querySuccess: false,
      suffixText: '发送验证码',
      flag: 'active',
      count: 60,
    })
    if (this.formRef && this.formRef.current) {
      this.formRef.current.resetFields()
    }
    this.getCaptcha()
    this.goPage(url)
  }

  onFinish = (value) => {
    const { pageType, loading } = this.state;
    const { dispatch } = this.props;
    const queryConfig = pageConfig[pageType] || ''
    const url = queryConfig.postUrl
    if (loading || !url) return
    let errMsg = ''
    if (['register', 'password'].includes(pageType) && value.password && value.password !== value.password1) {
      errMsg = '请填写相同的新密码'
    }
    if (errMsg) {
      message.error(errMsg)
      return
    }
    const data = { ...value }
    if (value.contactNumber) {
      data.contactNumber = value.contactNumber.contactNumber
      data.telephoneCode = value.contactNumber.tel
    }
    this.setState({ loading: true })
    post(url, data).then(res => {
      const { code, msg } = res || {}
      const successMsg = `${queryConfig.buttonName || '提交'}成功！`
      if (code === 200) {
        message.success(successMsg)
        setTimeout(() => {
          this.setState({ loading: false, querySuccess: true })
          if (pageConfig[pageType] && pageConfig[pageType].jumpUrl) {
            this.goPage(pageConfig[pageType].jumpUrl)          
          } 
        }, 1500)               
        getSession(dispatch);
      } else {
        message.error(msg || '网络问题，请稍后再试')
        this.setState({ loading: false })
        this.getCaptcha()
      }
    })
  }

  backCount = (count) => {
    if (count > 0) {
      setTimeout(() => {
        this.setState({ suffixText: `${count - 1}s后重新发送`, count: count - 1 }, () => {
          this.backCount(count - 1)
        })
      }, 1000)
    } else {
      this.setState({ flag: 'active', suffixText: '重新发送验证码', count: 60 })
    }
  }

  getCode = (action) => {
    this.formRef.current.validateFields(['contactNumber', 'captcha1']).then(value => {
      this.setState({ loading: true })
      const { contactNumber, captcha1 } = value
      const url = `/api/user/captcha?telephoneCode=${contactNumber.tel}&contactNumber=${contactNumber.contactNumber}&template=${action}&captcha=${captcha1}`
      get(url).then((res) => {
        const { code, msg } = res || {}
        if (code !== 200 && msg) {
          this.getCaptcha()
          message.error(msg)
          this.setState({ loading: false })          
        } else {
          this.setState({ loading: false, flag: 'disabled', suffixText: `60s后重新发送` }, () => {
            this.backCount(60)
          })
        }
      })
    }).catch((err) => {
      const errMsgs = (err.errorFields && err.errorFields[0] && err.errorFields[0].errors) || []
      if (errMsgs[0]) {
        message.error(errMsgs[0])
      }
    })
  }

  getSuffix = (action) => {
    const { loading, suffixText, flag } = this.state
    if (loading || flag !== 'active') {
      return <div className="suffixDisable">{suffixText}</div>
    }
    return <div className="suffixActive" onClick={()=>this.getCode(action)}>{suffixText}</div>
  }

  renderCaptcha = () => {
    const { captcha } = this.state
    const captChaCodeHtml = { __html: captcha || null };
    return (
      <Tooltip placement="top" title="点击更新">
        <div
          style={{ width: 150, height: 32, cursor: 'pointer' }}
          dangerouslySetInnerHTML={captChaCodeHtml}
          onClick={() => this.getCaptcha()}
        />
      </Tooltip>
    )
  }

  renderLogin = () => {
    const captcha = this.renderCaptcha()
    return (
      <div>
        <FormItem
          name='contactNumber'
          label="手机号码"
          rules={[
            { required: true, message: '请填写手机号' },
            {
              validator: (_, value) => {
                if(value && !value.contactNumber) {
                  return Promise.reject(new Error('请填写手机号'))
                } else if (value && value.contactNumber && !/^\d+$/.test(value.contactNumber)) {
                  return Promise.reject(new Error('请填写数字'))
                }
                return Promise.resolve()
              }
            }
          ]}
        >
          <GlobalTel chooseList={countryTel} />
        </FormItem>        
        <FormItem name='password' label="密码" rules={[{ required: true, message: '请填写密码' }]}>
          <Input.Password placeholder='密码' />
        </FormItem>
        <FormItem label='验证码' required>
          <div className="captchaInput">
            <div style={{ width: '100%' }}>
              <FormItem name='captcha' rules={[{ required: true, message: '请先填写验证码' }]}>
                <Input placeholder='验证码' style={{ width: '100%' }} />
              </FormItem>
            </div>
            <div className="captchaArea">{captcha}</div>
          </div>
        </FormItem>
      </div>
    )
  }

  renderRegister = () => {
    const suffix = this.getSuffix('register')
    const captcha = this.renderCaptcha()
    return (
      <div>
        <FormItem
          name='contactNumber'
          label="手机号码"
          rules={[
            { required: true, message: '请填写手机号' },
            {
              validator: (_, value) => {
                if(value && !value.contactNumber) {
                  return Promise.reject(new Error('请填写手机号'))
                } else if (value && value.contactNumber && !/^\d+$/.test(value.contactNumber)) {
                  return Promise.reject(new Error('请填写数字'))
                }
                return Promise.resolve()
              }
            }
          ]}
        >
          <GlobalTel chooseList={countryTel} />
        </FormItem>
        <FormItem label='验证码' required>
          <div className="captchaInput">
            <div style={{ width: '100%' }}>
              <FormItem name='captcha1' rules={[{ required: true, message: '请先填写验证码' }]}>
                <Input placeholder='验证码' style={{ width: '100%' }} />
              </FormItem>
            </div>
            <div className="captchaArea">{captcha}</div>
          </div>
        </FormItem>
        <FormItem name='captcha' label='短信验证码' rules={[{ required: true, message: '请填写短信验证码' }]}>
          <Input placeholder='短信验证码' suffix={suffix} />
        </FormItem>
        <FormItem name='password' label='登录密码' rules={[{ required: true, message: '请填写登录密码' }, passwordRules]}>
          <Input.Password placeholder='请输入8~20位密码' />
        </FormItem>
        <FormItem name='password1' label='确认密码' rules={[{ required: true, message: '请填写确认密码' }]}>
          <Input.Password placeholder='请再次确认密码' />
        </FormItem>
        <FormItem name='name' label='真实姓名' rules={[{ required: true, message: '请填写真实姓名' }]}>
          <Input placeholder='真实姓名' />
        </FormItem>
        <FormItem name='cardType' label='证件类型'>
          <Select style={{ width: '100%' }} placeholder='请选择'>
            {cardType.map(ele => (<Option key={ele.id} value={ele.id}>{ele.name}</Option>))}
          </Select>
        </FormItem>
        <FormItem name='cardNum' label='证件号码'>
          <Input placeholder='证件号码' />
        </FormItem>
        <FormItem name='email' label='电子邮箱' rules={[emailRules]}>
          <Input placeholder='电子邮箱' />
        </FormItem>
        <FormItem
          name='agreement'
          valuePropName="checked"
          rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('请阅读并同意相关条款')) }]}
          {...tailFormItemLayout}
        >
          <Checkbox>
            我已阅读并同意 <a href="/needKnow?type=user" target="_blank" >《用户协议》</a>，<a href="/needKnow?type=service" target="_blank" >《服务隐私条款》</a>
          </Checkbox>
        </FormItem>
      </div>
    )
  }

  renderPassword = () => {
    const suffix = this.getSuffix('findPwd')
    const captcha = this.renderCaptcha()
    return (
      <div>
        <FormItem
          name='contactNumber'
          label="手机号码"
          rules={[
            { required: true, message: '请填写手机号' },
            {
              validator: (_, value) => {
                if (value && !value.contactNumber) {
                  return Promise.reject(new Error('请填写手机号'))
                } else if (value && value.contactNumber && !/^\d+$/.test(value.contactNumber)) {
                  return Promise.reject(new Error('请填写数字'))
                }
                return Promise.resolve()
              }
            }
          ]}
        >
          <GlobalTel chooseList={countryTel} />
        </FormItem>
        <FormItem label='验证码' required>
          <div className="captchaInput">
            <div style={{ width: '100%' }}>
              <FormItem name='captcha1' rules={[{ required: true, message: '请先填写验证码' }]}>
                <Input placeholder='验证码' style={{ width: '100%' }} />
              </FormItem>
            </div>
            <div className="captchaArea">{captcha}</div>
          </div>
        </FormItem>
        <FormItem name='captcha' label='短信验证码' rules={[{ required: true, message: '请填写短信验证码' }]}>
          <Input placeholder='短信验证码' suffix={suffix} />
        </FormItem>
        <FormItem name='password' label='新密码' rules={[{ required: true, message: '请填写新密码' }, passwordRules]}>
          <Input.Password placeholder='请输入8~20位登录密码' />
        </FormItem>
        <FormItem name='password1' label='确认新密码' rules={[{ required: true, message: '请填写确认新密码' }]}>
          <Input.Password placeholder='确认密码' />
        </FormItem>
      </div>
    )
  }

  renderForm = (type) => {
    let list = null
    switch (type) {
      case 'login':
        list = this.renderLogin();
        break;
      case 'register':
        list = this.renderRegister();
        break;
      case 'password':
        list = this.renderPassword();
        break;
      default:
        break
    }

    return list
  }

  render() {
    const { pageType, querySuccess } = this.state;
    const curConfig = pageConfig[pageType] || {}
    return (
      <div className="login">
        <div className="title">{curConfig.title}</div>
        <div className="loginArea" style={pageType !== 'register' ? { paddingTop: '90px', paddingBottom: '90px' } : {}}>
          {!(pageType === 'password' && querySuccess) ? (
            <div className="operationArea">
              <div className="formArea" style={pageType === 'login' ? { width: '600px' } : {}}>
                <Form ref={this.formRef} onFinish={this.onFinish} {...(curConfig.layout || {})} initialValues={{}} validateTrigger={['onFinish', 'onBlur']}>
                  {this.renderForm(pageType)}
                  <FormItem {...(curConfig.buttonLayout || {})}>
                    <Button type="primary" htmlType="submit" style={{ width: '100%', height: '42px' }}>
                      {curConfig.buttonName}
                    </Button>
                  </FormItem>
                  <FormItem {...(curConfig.buttonLayout || {})}>
                    {(pageType === 'login' && (
                      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-30px' }}>
                        <div className="suffixActive" onClick={() => this.changePage('password')}>忘记密码</div>
                        <div className="suffixActive" onClick={() => this.changePage('register')}>注册</div>
                      </div>
                    )) || null}
                    {(pageType === 'register' && (
                      <div style={{ textAlign: 'center', marginTop: '-30px' }}>
                        <div className="suffixActive" onClick={() => this.changePage('login')}>已有账号，去登录</div>
                      </div>
                    )) || null}
                  </FormItem>
                </Form>
              </div>
              {(pageType === 'login' && (<div className="divider" />)) || null}
              {(pageType === 'login' && (
                <div className="qCodeArea">
                  <img src="./image/qc.png" alt='qc' />
                  <div>扫描关注微信公众号</div>
                </div>
              )) || null}
            </div>
          ) : (
            <div className="success">
              <div className="icon"><CheckCircleFilled /></div>
              <div className="successText">重置成功</div>
              <Button type="primary" onClick={() => this.changePage('login')} className="button">返回登录</Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Login;
