import React, { Component } from 'react'
import { Input, DatePicker, Select, Form } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import {
  patentTypeConf,
  AFTERTODAY
} from '../../../utils/config'
import config from '../config'
import './index.less'

const { Option } = Select;
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const formItemLayoutWithoutLabel = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16, offset: 8 },
  },
};
class Patent extends Component {
  render() {
    const { fields, add, remove } = this.props;
    return (
      <div>
        {fields.map(({ key, name, fieldKey, ...restField }) =>
          <div key={key} className='card'>
            <FormItem {...restField} wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 6 },
            }} style={{ width: '84%', marginBottom: 0 }}>
              <FormItem {...formItemLayout} label='专利名称' fieldKey={[fieldKey, 'name']} name={[name, 'name']} {...config.patent_name}>
                <Input placeholder='请输入专利名称' />
              </FormItem>
              <FormItem {...formItemLayout} label='专利类别' fieldKey={[fieldKey, 'type']} name={[name, 'type']} {...config.patent_type}>
                <Select placeholder='请选择' >
                  {patentTypeConf.map(v => <Option key={v.id} value={v.id}>{v.name}</Option>)}
                </Select>
              </FormItem>
              <FormItem {...formItemLayout} label='专利号' fieldKey={[fieldKey, 'applicationNo']} name={[name, 'applicationNo']} {...config.patent_applicationNo}>
                <Input placeholder='请输入专利号' />
              </FormItem>
              <FormItem {...formItemLayout} label='获得时间' fieldKey={[fieldKey, 'publishedAt']} name={[name, 'publishedAt']} {...config.patent_publishedAt}>
                <DatePicker disabledDate={AFTERTODAY} />
              </FormItem>
              <FormItem {...formItemLayoutWithoutLabel} >
                <span style={{ color: '#1890ff', marginRight: '20px', cursor: 'pointer' }} onClick={() => add({}, name + 1)}><PlusCircleOutlined style={{ color: '#1890ff', fontSize: 18, marginRight: '5px' }} type="dashed" />添加</span>
                {name > 0 && <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={() => remove(name)} ><MinusCircleOutlined style={{ color: '#1890ff', fontSize: 18, marginRight: '5px' }} />删除</span>}
              </FormItem>
            </FormItem>
            {/* {fields.length > 1 && <MinusCircleOutlined style={{ fontSize: 24, cursor: 'pointer' }} onClick={() => remove(name)} />} */}
          </div>
        )
        }
        {/* <FormItem wrapperCol={{
                    xs: { span: 24, offset: 0 },
                    sm: { span: 12, offset: 11 },
                }} style={{ marginTop: '10px' }} >
                    <Button type="dashed" onClick={() => add()} >新增</Button>
                </FormItem> */}
      </div>
    )
  }
}

export default Patent;