import React, { Component } from 'react'
import { Menu } from 'antd'
import { connect } from "react-redux";
import { getRegistrationDetail, getUser, getSession } from '../../store/action/home'
import { getUrlSearchObj } from '../../utils/utils';
import MyRegister from './myRegister';
import MyMessage from './myMessage';
import MyInfo from './myInfo';
import './index.less'


const menuList = [{
  label: '我的报名',
  value: 'myRegistration'
}, {
  label: '消息通知',
  value: 'message'
}, {
  label: '个人资料',
  value: 'baseInfo'
}]

const showComponent = (key, props) => {
  const compConfig = {
    myRegistration: MyRegister,
    message: MyMessage,
    baseInfo: MyInfo, 
  }
  const Comp = compConfig[key]
  return <Comp {...props} />
}

@connect((state) => state)
class PersonalCenter extends Component {
  constructor(props) {
    super(props)
    const { location: { search } } = props;
    const queryParams = getUrlSearchObj(search || '')
    this.state = {
      menuPath: [queryParams.type || 'myRegistration'],
      pageKey: queryParams.type || 'myRegistration'
    }
  }

  componentDidMount() {
    const { dispatch, home, history } = this.props;
    const { user } = home || {}
    if (!user) {
      getSession(dispatch).then(res => {
        if (!res) {
          history.push('/home')
        }
      })
    }
    getRegistrationDetail(dispatch)
    getUser(dispatch)
    window.scrollTo(0, 0);
  }

  menuClick = (menu) => {
    // console.log({menu})
    const { history } = this.props;
    const { keyPath, key } = menu
    this.setState({ menuPath: keyPath, pageKey: key })
    history.push(`/personal?type=${key}`)
  }

  getUser = () => {
    const { dispatch } = this.props;
    getUser(dispatch)
  }

  render() {
    const { menuPath, pageKey } = this.state
    return (
      <div className="parsonal">
        <div className="p_menu">
          <Menu
            selectedKeys={menuPath}
            onClick={this.menuClick}
          >
            {menuList.map(v => <Menu.Item style={{ height: 52, lineHeight: '52px' }} key={v.value}>{v.label}</Menu.Item>)}
          </Menu>
        </div>
        <div className="p_content">
          {showComponent(pageKey, {...this.props, getUser: () => this.getUser()})}
        </div>
      </div>
    )
  }
}

export default PersonalCenter;