import React, { Component } from "react";
import { Button, Card } from "antd";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getSession, getRegistrationDetail } from "../../store/action/home";
import { getUrlSearchObj } from '../../utils/utils';
import "./index.less";

const titleConfig = {
  register: "报名须知", user: '用户服务协议', service: '服务隐私条款'
}
@withRouter
@connect((state) => state.home)
class NeedKnow extends Component {
  constructor(props) {
    super(props);
    const { location: { search } } = props;
    const queryParams = getUrlSearchObj(search || '')
    this.state = {
      pageType: queryParams.type || 'register'
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    getSession(dispatch);
    getRegistrationDetail(dispatch)
  }

  hasRead = () => {
    const { history, user, registrationDetail } = this.props;
    if (user && registrationDetail && registrationDetail.registDate) {
      history.push("/personal");
    } else {
      history.push("/enter");
    }
  };

  renderRegister = () => (
    // <div>
    //   <div>
    //     <h2>一、参赛条件及参赛者权利与义务</h2>
    //     <h3> 1. 参赛条件：</h3>
    //     <ul>
    //       <li>
    //         （1）参赛项目核心团队成员至少有一人为华中科技大学校友或在校师生。若已注册成立企业，校友占股不得低于10%，
    //         <span>参赛项目负责人须为华中科技大学校友或在校师生</span>。
    //       </li>
    //       <li>
    //         （2）
    //         <span>
    //           每个参赛项目根据企业工商登记注册地址及业务覆盖地域等，必须且只能选择代表1个地方校友会参赛
    //         </span>
    //         。
    //       </li>
    //       <li>
    //         （3）华中科技大学校友创立或担任核心成员（股东或经营管理层人员）的知名投资机构所投项目，经校友推荐也可参赛。
    //       </li>
    //       <li className="groups">
    //         （4）大赛分为创意组、初创组和成长组：
    //         <span>
    //           {" "}
    //           创意组。参赛项目具有较好的创意和初步成型的产品原型或服务模式，在2021年10月28日前尚未完成工商登记注册。
    //         </span>
    //         <span>
    //           初创组。参赛项目工商登记注册未满3年（2018年10月28日（含）之后注册），且获机构或个人股权投资累计不超过1000万元，年营收不超过1000万元人民币。
    //         </span>
    //         <span>
    //           {" "}
    //           成长组。参赛项目工商登记注册3年以上（2018年10月28日之前注册），或工商登记注册未满3年，获机构或个人股权投资累计超过1000万元，年营收超1000万元人民币。
    //         </span>
    //       </li>
    //       <li>
    //         （5）若参赛项目已完成工商登记工作，需为经营规范、社会信誉良好、无不良记录的非上市企业；若参赛项目未完成工商登记工作，需具有创新能力和高成长潜力，主要从事高新技术产品的研发、制造、服务等业务。
    //       </li>
    //       <li>
    //         （6）本次大赛参赛项目主要征集领域为大数据、人工智能、芯片、互联网+、健康医疗、新材料、先进制造、新能源、文化创意等新技术新产业。
    //       </li>
    //       <li>
    //         （7）参赛项目涉及的产品、技术及相关专利归属参赛团队或企业，与其他任何单位或个人无产权纠纷，不得侵犯他人知识产权；所涉及的发明创造、专利技术、资源等必须拥有清晰合法的知识产权或物权；抄袭、盗用、提供虚假材料或违反相关法律法规一经发现即刻丧失参赛相关权利并自负一切法律责任。
    //       </li>
    //     </ul>
    //     <h3> 2.参赛者的权利和义务</h3>
    //     <h3>
    //       大赛参赛者在符合参赛规定的条件下参加比赛，享有以下权利和义务：
    //     </h3>
    //     <ul>
    //       <li>（1）公平竞争的权利；</li>
    //       <li>（2）向大赛组委会咨询和申诉的权利；</li>
    //       <li>
    //         （3）认真遵守大赛章程及相关规则，积极配合大赛组织机构工作的义务；
    //       </li>
    //       <li> （4）其他大赛组委会审议通过的相关权利和义务。</li>
    //     </ul>
    //   </div>

    //   <div>
    //     <h2> 二、赛制设置及赛程安排</h2>
    //     <h3> 1.赛制设置</h3>
    //     <h3>
    //       本次大赛初赛、复赛阶段分六个分赛区举办，赛区和承办单位分别为东部赛区（浙江校友会）、南部赛区（深圳校友会）、西部赛区（四川校友会）、北部赛区（青岛校友会）、中部赛区（武汉校友会）、海外赛区（法国校友会），决赛地点定于华中科技大学。
    //     </h3>
    //     <h3> 赛区划分表</h3>
    //     <table width={1000} style={{ textAlign: "left" }}>
    //       <thead>
    //         <tr>
    //           <th>赛 区</th>
    //           <th>举办地</th>
    //           <th>省、市、自治区</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         <tr>
    //           <td>东部赛区</td>
    //           <td>杭 州</td>
    //           <td>浙江省、上海市、江苏省、福建省</td>
    //         </tr>
    //         <tr>
    //           <td>南部赛区</td>
    //           <td>深 圳</td>
    //           <td>广东省、广西壮族自治区、海南省、香港、澳门、台湾</td>
    //         </tr>
    //         <tr>
    //           <td>西部赛区</td>
    //           <td>成 都</td>
    //           <td>
    //             四川省、重庆市、陕西省、甘肃省、新疆维吾尔自治区、云南省、贵州省、青海省、宁夏回族自治区、西藏自治区
    //           </td>
    //         </tr>
    //         <tr>
    //           <td>北部赛区</td>
    //           <td>青 岛</td>
    //           <td>
    //             山东省、北京市、天津市、山西省、河北省、内蒙古自治区、辽宁省、吉林省、黑龙江省
    //           </td>
    //         </tr>
    //         <tr>
    //           <td>中部赛区</td>
    //           <td>武 汉</td>
    //           <td>湖北省、湖南省、河南省、安徽省、江西省</td>
    //         </tr>
    //         <tr>
    //           <td>海外赛区</td>
    //           <td>法 国</td>
    //           <td>海外地区相关国家</td>
    //         </tr>
    //       </tbody>
    //     </table>
    //     <h3> 2.赛程安排：</h3>
    //     <li>2021年10月至2022年10月</li>
    //     <li>
    //       本次大赛一共分为7个阶段，包括：项目报名，项目海选，项目初赛，项目复赛，网络评选，导师辅导，项目决赛。
    //     </li>
    //     <li>项目报名：2021年10月至2022年1月</li>
    //     <li>
    //       选手通过华中科技大学校友总会的官方网站或官方微信公众号报名，提交参赛信息表、项目计划书及相关视频资料。
    //     </li>
    //     <li>项目海选：2022年2月</li>
    //     <li>
    //       各分赛区推荐评委会评委并由组委会聘任，评委根据项目参赛条件，对参赛项目进行形式和资格审查。
    //     </li>
    //     <li>项目初赛：2022年3月开始（6个赛区分批次进行）</li>
    //     <li>
    //       分赛区初赛由各分赛区组织（各分赛区根据实际情况，在所在赛区相关城市设置城市赛点，组织开展初赛项目评选活动），分赛区推荐不少于5位评委会评委并由组委会聘任，评委依据大赛评审规则对各组项目分别进行线下评审，根据打分排名，优选出15个创意组、15个初创组、15个成长组优秀项目，进入分赛区复赛。
    //     </li>
    //     <li>项目复赛：2022年5月开始（6个赛区分批次进行）</li>
    //     <li>
    //       分赛区复赛由各分赛区组织，分赛区推荐不少于5位评委会评委并由组委会聘任，评委对各组项目的项目路演、答辩情况进行评分，择优选取入围项目进入网络评选或决赛。复赛设置待定卡与直通卡，获取直通卡可直通决赛，待定选手需参加网络评选。各赛区分别优选出创意组1个项目，初创组1个项目、成长组1个项目，获取直通卡进入大赛总决赛；创意组5个项目、初创组5个项目、成长组5个项目，获取待定卡进入项目网络评选。
    //     </li>
    //     <li>网络评选：2022年7月</li>
    //     <li>
    //       各赛区复赛拿到待定卡的参赛项目统一参加网络评选。由大赛组委会组织网络投票，并邀请评委会评委参与评选，根据网络投票情况及评委会评审情况选出有资格进入决赛的30个项目。
    //     </li>
    //     <li>导师辅导：2022年7月至2022年9月</li>
    //     <li>
    //       各赛区承办单位邀请评委会评委对入围决赛项目进行辅导，提升项目的技术水平并探索研究成果的转化方式，并进行产业化与融资指导，后续跟进项目进程与实际效果。
    //     </li>
    //     <li>项目决赛：2022年10月</li>
    //     <li>
    //       大赛组委会统一组织决赛，进行现场答辩，邀请评委会评选出一等奖、二等奖、三等奖、优秀奖及各项提名奖。
    //     </li>
    //   </div>

    //   <div>
    //     <h2>六、奖项设置</h2>
    //     <h3>1.项目奖励</h3>
    //     <li>
    //       大赛设立奖项及奖金，其中一等奖1名，二等奖3名，三等奖5名，优秀奖10名及各项提名奖。由校友总会颁发证书及奖金。
    //     </li>
    //     <h3>2.团体奖项</h3>
    //     <li>
    //       以地方校友组织为单位计算团体总分，一等奖项目每件计100分，二等奖项目每件计70分，三等奖项目每件计40分，优秀奖项目每件计20分。报送的项目通过了资格审查的每件计10分。设立团体奖项若干，根据团体总分获得情况颁发奖项。
    //     </li>
    //   </div>

    //   <div>
    //     <h2>七、 附则</h2>
    //     <li>
    //       1.大赛结束后，对获奖项目保留一个月的质疑投诉期。若收到投诉，大赛评审委员会将委托主办单位有关部门进行调查。经调查，如确认该项目资格不符者，取消该项目获得的奖励并进行公开通报。大赛评审委员会不接受匿名投诉，将保护实名投诉人的合法权益。
    //     </li>
    //     <li>
    //       2.大赛承办单位有权以组委会名义寻求社会各方的赞助，经与组委会秘书处报备，可对赞助单位以特别鸣谢等形式宣传，但不得以赞助单位名称冠名各赛区比赛。各赛区比赛统一使用“华中科技大学首届全球校友创新创业大赛XX赛区”。各赛区募集赞助经费的使用情况需向组委会报告。
    //     </li>
    //     <li>
    //       3.若参赛者为项目所涉及知识产权授权许可使用取得者，报名时需提交完整的具有法律效力的所有人书面授权许可书、专利证书等。若收到举报，大赛评审委员会将委托有关部门进行调查。调查期间要求被举报人提交相关证明文件，如确认该项目资格不符者，取消该项目获得的支持并进行公开通报。大赛评审委员会不接受匿名投诉，将保护实名投诉人的合法权益。
    //     </li>
    //     <li>
    //       4.本实施方案自组委会会议通过之日起生效，由组委会及秘书处负责解释。
    //     </li>
    //   </div>
    // </div>
    <div>
      <h3>一、参赛方式和要求</h3>
      <div className='sectionText'>1. 大赛以团队为单位报名参赛，团队成员须为项目实际核心成员。核心成员（创始人、股东、高管）中至少有1人为华中科技大学校友或在校师生。若校友或在校师生为企业股东，合计占股不得低于10%。</div>
      <div className='sectionText'>2. 参赛项目负责人须为华中科技大学校友或在校师生，后续比赛展示环节需由项目负责人主要完成。</div>
      <div className='sectionText'>3. 每个参赛项目根据企业工商登记注册地址及业务覆盖地域等，必须且只能选择代表1个地方校友会参赛。</div>
      <div className='sectionText'>4. 若参赛项目已完成工商登记，需为经营规范、社会信誉良好、无不良记录的非上市企业；若参赛项目未完成工商登记，需具有创新能力和高成长潜力，主要从事高新技术产品的研发、制造、服务等业务。</div>
      <div className='sectionText'>5. 大赛重点面向大数据、人工智能、芯片、互联网+、健康医疗、新材料、先进制造、新能源、文化创意等新技术新产业领域征集项目。</div>
      <div className='sectionText'>6.参赛项目涉及的产品、技术及相关专利需归属参赛团队或企业，与其他任何单位或个人无产权纠纷，不得侵犯他人知识产权；所涉及发明创造、专利技术、资源等必须拥有清晰合法的知识产权或物权；若存在抄袭、盗用、提供虚假材料或违反相关法律法规的行为，一经发现即刻丧失参赛相关权利并自负一切法律责任。</div>
      <br />
      <br />
      <h3>二、参赛组别和对象</h3>
      <div className='sectionText'>根据参赛项目所处创业阶段、已获投资及营收等情况，分为创意组、初创组和成长组。具体参赛条件如下：</div>
      <div className='sectionText'>1. 创意组。参赛项目具有较好创意和初步成型的产品原型或服务模式，在2021年10月28日前尚未完成工商登记注册。</div>
      <div className='sectionText'>2. 初创组。参赛项目工商登记注册未满3年（2018年10月28日（含）之后注册），且获机构或个人股权投资累计不超过1000万元人民币，及年营收不超过1000万元人民币。</div>
      <div className='sectionText'>3. 成长组。参赛项目工商登记注册3年以上（2018年10月28日之前注册）；或工商登记注册未满3年，获机构或个人股权投资累计超过1000万元人民币；或工商登记注册未满3年，年营收超过1000万元人民币。</div>
    </div>
  )

  renderUser = () => (
    <div>
      <div className='sectionText'>欢迎您使用华中科技大学全球校友创新创业大赛服务网（以下简称我们或本网站）。本网站由华中科技大学校友总会（简称中心或我们）所有和运营。</div>
      <div className='sectionText'>请务必认真阅读和理解本《用户服务协议》（以下简称《协议》）中规定的所有权利和限制。除非您接受本《协议》条款，否则您无权注册、登录或使用本协议所涉及的相关服务。您一旦注册、登录、使用或以任何方式使用本《协议》所涉及的相关服务的行为将视为对本《协议》的接受，即表示您同意接受本《协议》各项条款的约束。如果您不同意本《协议》中的条款，请不要注册、登录或使用本《协议》相关服务。本《协议》是用户与华中科技大学校友总会之间的法律协议。</div>
      <h3>一、总则</h3>
      <div className='sectionText'>1. 华中科技大学全球校友创新创业大赛服务网的所有权和运营权归华中科技大学校友总会所有。</div>
      <div className='sectionText'>2. 您访问本网站、注册成为本网站用户或使用本网站的任何产品或服务，即视为您已阅读、了解并完全同意本协议中的各项内容，包括本网站对条款内容所作的任何修改。同时也代表您同意我们的《隐私政策》，其内容涵盖了我们如何收集、使用、披露、存储和保护您的个人信息。若您不同意本协议，请不要注册、访问或以其他方式使用本网站提供的任何产品或服务。</div>
      <div className='sectionText'>3. 本协议的内容可能会不时修改，以适应法律、技术或业务的发展。用户应当及时关注并同意。</div>
      <div className='sectionText'>修改后的协议将在本网站公布，本站不承担通知义务，重大变更将以网站公告、用户通知等合适的形式向您告知。</div>
      <div className='sectionText'>在我们发布或发送关于条款更改的通知后，若反对任何更改，您可注销账号（联系客服人工注销）或停止使用我们提供的产品或服务。若您继续使用我们的任何产品或服务，即表示您同意修改后的条款。</div>
      <div className='sectionText'>修改内容的适用不具有追溯力。</div>
      <h3>二、服务内容</h3>
      <div className='sectionText'>1. 华中科技大学全球创新创业大赛服务网的具体内容由本站根据实际情况提供。</div>
      <div className='sectionText'>2. 本站仅提供相关的网络服务，除此之外与相关网络服务有关的设备(如个人电脑、手机、及其他与接入互联网或移动网有关的装置)及所需的费用(如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费)均应由用户自行承担。</div>
      <h3>三、账号的管理和使用</h3>
      <div className='sectionText'>1. 您可以使用手机号进行注册，获得本网站账号和密码，登录本网站。您在本网站的账号、密码等是您在本网站的唯一身份权证。您通过账号和密码使用本网站的行为，均视为您本人行为，您应当承担由此导致的相关后果和责任。</div>
      <div className='sectionText'>2．如果您是未满16岁的未成年人，须在监护人的陪同和帮助下仔细阅读本协议，并在征得您监护人同意的前提下，使用我们的产品或服务或向我们提供信息。</div>
      <div className='sectionText'>3．经本站注册成功的用户，可通过不同的身份认证获得本站规定对应身份所应享有的相关权限；不同身份权限不同，本站有权对会员的权限设计进行变更。</div>
      <div className='sectionText'>4．用户只能按照注册要求进行注册。用户有义务保证密码和账号的安全，用户利用该密码和账号所进行的一切活动引起的任何损失或损害，由用户自行承担全部责任，本站不承担任何责任。如用户发现账号遭到未授权的使用或发生其他任何安全问题，应立即修改账号密码并妥善保管，如有必要，请通知本站。因黑客行为或用户的保管疏忽导致账号非法使用，本站不承担任何责任。</div>
      <div className='sectionText'>5. 您应当妥善保管您的账号、密码和手机短信验证码，避免使用过于简单的密码。不得将您的账号和密码出租、出借、转让或者以其他方式提供给他人使用。您可以根据本网站规定更改密码、更新个人注册信息。如果您发现自己的信息泄露，尤其是本网站账号和密码发生泄露，请立即联系我们。</div>
      <div className='sectionText'>6．您在使用具体产品或服务时，我们可能会要求您进一步提供本人身份信息及资料，以完成身份信息核验，您应保证提供的身份信息及资料为本人真实、准确的信息。</div>
      <h3>四、用户的权利与义务</h3>
      <div className='sectionText'>1. 遵守中华人民共和国相关法律法规，包括但不限于《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《最高人民法院关于审理涉及计算机网络著作权纠纷案件适用法律若干问题的解释(法释[2004]1号)》、《全国人大常委会关于维护互联网安全的决定》、《互联网电子公告服务管理规定》、《互联网新闻信息服务管理规定》、《互联网著作权行政保护办法》和《信息网络传播权保护条例》等有关计算机互联网规定和知识产权的法律和法规、实施办法。</div>
      <div className='sectionText'>2. 在遵守有关法律法规及本协议约定的前提下，您有权按照产品或服务的要求，使用我们提供的产品或服务。</div>
      <div className='sectionText'>3. 我们提供的部分产品或服务需要获得您授权终端设备的部分信息方可正常使用，我们按照相关法律法规，以最小化权限原则进行授权获取的提示。您有权不同意，但可能会影响您正常使用我们的产品或服务。</div>
      <div className='sectionText'>4. 您使用本网站应当符合国家法律法规的规定，且行为符合下列要求：</div>
      <div className='sectionText'>(1) 不干扰本网站的正常运转，不进行任何破坏或试图破坏网络安全的行为，不进行任何改变或试图改变本网站系统配置或破坏系统安全的行为，不得侵入或试图侵入本网站信息系统和管理后台；</div>
      <div className='sectionText'>(2) 不对本网站展示或提供的、由本网站所有的任何数据或信息作商业性利用，包括但不限于在未经本网站事先书面同意的情况下，以复制、传播等任何方式使用本网站展示或提供的、由本网站所有的资料、信息、数据等；</div>
      <div className='sectionText'>(3) 不模仿、修改、翻译、改编、出借、出售、转许可、传播或转让本网站提供的服务，也不得逆向工程、反汇编、反编译、分解拆卸或试图以其他方式发现本网站提供的服务的源代码；</div>
      <div className='sectionText'>(4) 不发送、上传和储存带有病毒、蠕虫、木马和其他有害内容的计算机代码、文件、脚本和程序；</div>
      <div className='sectionText'>(5) 遵守所有与网络服务有关的的协议、规定、程序和惯例；</div>
      <div className='sectionText'>(6) 不从事其他违反法律法规规定或本协议约定的行为。</div>
      <div className='sectionText'>5. 用户对其自行发表、上传或传送的内容负全部责任，所有用户不得在本站任何页面发布、转载、传送含有下列内容之一的信息，否则本站有权自行处理并不通知用户：</div>
      <div className='sectionText'>(1) 违反宪法确定的基本原则的；</div>
      <div className='sectionText'>(2) 危害国家安全，泄漏国家机密，颠覆国家政权，破坏国家统一的；</div>
      <div className='sectionText'>(3) 损害国家荣誉和利益的；</div>
      <div className='sectionText'>(4) 煽动民族仇恨、民族歧视，破坏民族团结的；</div>
      <div className='sectionText'>(5) 破坏国家宗教政策，宣扬邪教和封建迷信的；</div>
      <div className='sectionText'>(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；</div>
      <div className='sectionText'>(7) 散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</div>
      <div className='sectionText'>(8) 侮辱或者诽谤他人，侵害他人合法权益的；</div>
      <div className='sectionText'>(9) 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</div>
      <div className='sectionText'>(10) 以非法民间组织名义活动的；</div>
      <div className='sectionText'>(11) 含有法律、行政法规禁止的其他内容的。</div>
      <div className='sectionText'>6. 用户承诺对其发表或者上传于本站的所有信息(即属于《中华人民共和国著作权法》规定的作品，包括但不限于文字、图片、音乐、电影、表演和录音录像制品和电脑程序等)均享有完整的知识产权，或者已经得到相关权利人的合法授权；如用户违反本条规定造成本站被第三人索赔的，用户应全额补偿本站一切费用(包括但不限于各种赔偿费、诉讼代理费及为此支出的其它合理费用)；</div>
      <div className='sectionText'>7. 当第三方认为用户发表或者上传于本站的信息侵犯其权利，并根据《信息网络传播权保护条例》或者相关法律规定向本站发送权利通知书时，用户同意本站可以自行判断决定删除涉嫌侵权信息，除非用户提交书面证据材料排除侵权的可能性，本站将不会自动恢复上述删除的信息；</div>
      <div className='sectionText'>(1) 不得为任何非法目的而使用网络服务系统；</div>
      <div className='sectionText'>(2) 遵守所有与网络服务有关的网络协议、规定和程序；</div>
      <div className='sectionText'>(3) 不得利用本站进行任何可能对互联网的正常运转造成不利影响的行为；</div>
      <div className='sectionText'>(4) 不得利用本站进行任何不利于本站的行为。</div>
      <div className='sectionText'>8. 如用户在使用网络服务时违反上述任何规定，本站有权要求用户改正或直接采取一切必要的措施(包括但不限于删除用户张贴的内容、暂停或终止用户使用网络服务的权利)以减轻用户不当行为而造成的影响。</div>
      <div className='sectionText'>9．若您存在违反法律规定或本协议约定的任一情形，本网站有权按相关规定暂停或终止提供服务，保存有关记录，并向政府主管部门报告，由此引起的一切后果和责任由您负责。同时，本网站有权终止本协议，并不承担任何责任。</div>
      <div className='sectionText'>10．您违反本协议使用本网站产品或服务导致本网站或第三方损失的，应当承担相应法律责任、赔偿本网站或第三方的损失。</div>
      <h3>五、隐私保护</h3>
      <div className='sectionText'>1. 本站不对外公开或向第三方提供用户的注册资料及用户在使用网络服务时存储在本站的非公开内容，但下列情况除外：</div>
      <div className='sectionText'>(1) 事先获得用户的明确授权；</div>
      <div className='sectionText'>(2) 根据有关的法律法规要求；</div>
      <div className='sectionText'>(3) 按照相关政府主管部门的要求；</div>
      <div className='sectionText'>(4) 为维护社会公众的利益。</div>
      <div className='sectionText'>2. 在不透露用户隐私资料的前提下，本站有权对整个用户数据库进行分析利用。</div>
      <h3>六、我们的权利和义务</h3>
      <div className='sectionText'>1．我们有义务在现有技术上维护本网站的正常运行，并努力提升和改进技术方案，提供更好的产品或服务，使您顺利的使用本网站的产品或服务。我们也有义务在《隐私政策》框架内，保护本网站收集的您的个人信息。</div>
      <div className='sectionText'>2．对您在注册或使用我们提供的产品或服务过程中，遇到的有关问题或反映的情况，我们应及时作出回复。</div>
      <div className='sectionText'>3．由于部分产品或服务的需要，我们可能通过产品或服务中的通知或您提供的邮箱、手机号码等联系方式给您发送通知和服务信息，其方式和范围可不经向您特别通知而变更。</div>
      <div className='sectionText'>4．我们有权修改、暂停或终止任何免费产品或服务。</div>
      <div className='sectionText'>5．我们有权对您提供的资料信息进行核验，核验结果适用于您在本网站办理注册以及查询验证等相关服务，如您提供的资料不准确，或无法通过核验，或本网站有合理的理由认为该资料信息不真实、不完整、不准确，本网站有权暂停或终止您的注册身份及资料，并拒绝您使用本网站的服务。</div>
      <div className='sectionText'>6．我们有权删除利用他人身份信息非法注册的本网站账号，并保留追究其相关法律责任的权利。</div>
      <div className='sectionText'>7．对于您在本网站上的不当行为或其他任何本网站认为应当终止服务的情况，我们有权随时作出删除相关信息、终止服务提供等处理，而无须征得您的同意。</div>
      <div className='sectionText'>8．本网站的图形、图像、文字及其程序等均属本网站之版权，受商标法及相关知识产权法律保护；未经书面许可，任何人不得下载或复制，严禁对上述对象进行再使用。在本网站发布信息之商标，归其相应的商标所有权人，受商标法保护。</div>
      <h3>七、协议的终止</h3>
      <div className='sectionText'>本网站或您均可在任何时间通知对方终止本协议。一旦协议终止，您就失去了访问或使用本网站产品或服务的权利。但以下内容将不会终止：</div>
      <div className='sectionText'>(1) 本网站使用和公开您所反馈的意见的权利；</div>
      <div className='sectionText'>(2) 其他用户或访客分享您在协议终止之前被复制或分享的内容和信息的权利；</div>
      <div className='sectionText'>(3) 其他根据本协议内容应继续有效的条款，以及本协议终止前各方的相关权利和义务，包括但不限于守约方依据本协议向违约方追究相应的违约责任。</div>
      <h3>八、免责声明</h3>
      <div className='sectionText'>1．本网站不能保证网站不受黑客、病毒的侵害，不能完全保证网站无故障运行。因正常的系统维护、系统升级，或者因网络拥塞而导致网站不能访问，本网站不承担任何责任；</div>
      <div className='sectionText'>2．用户对在本网站中发布信息的准确性和合法性独立承担一切法律责任；</div>
      <div className='sectionText'>3．对于他方输入的、本网站上链接的其他网站等不是本网站发布的内容，本网站不承担任何法律责任；</div>
      <div className='sectionText'>4．华中科技大学校友总会不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由华中科技大学校友总会实际控制的任何网页上的内容，华中科技大学校友总会不承担任何责任；</div>
      <div className='sectionText'>5．对于因用户本人身份等信息泄露而导致本网站信息被他人获取所造成的任何损失，本网站不承担任何法律责任；</div>
      <div className='sectionText'>6．因非本网站原因导致用户的账号、密码遗失、遗忘或被他人窃取的，本网站不承担责任。</div>
      <div className='sectionText'>7. 对于因电信系统或互联网网络故障、计算机故障或病毒、信息损坏或丢失、计算机系统问题或其它任何不可抗力原因而产生损失，华中科技大学校友总会不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</div>
      <h3>九、适用法律和纠纷解决</h3>
      <div className='sectionText'>1. 本协议适用中华人民共和国法律，本用户协议遵守中华人民共和国法律，如发生与中华人民共和国法律相抵触时，则这些条款将完全按法律规定重新解释，而其他条款仍保持对用户产生法律效力和影响。</div>
      <div className='sectionText'>2. 您与本网站就本协议或其他有关事项发生的争议，应首先友好协商解决，协商不成时，应将纠纷或争议提请本网站备案归属地有管辖权的法院管辖。</div>
      <h3>十、其他条款</h3>
      <div className='sectionText'>1. 如果本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，或违反任何适用的法律，则该条款被视为删除，但本协议的其余条款仍应有效并且有约束力。</div>
      <div className='sectionText'>2. 华中科技大学校友总会在法律允许最大范围对本协议拥有解释权与修改权。</div>
    </div>
  )

  renderService = () => (
    <div>
      <h3>引言</h3>
      <div className='sectionText'>欢迎使用华中科技大学全球校友创新创业大赛服务网，华中科技大学全球校友创新创业大赛服务网（以下简称“我们”）为华中科技大学校友总会所有及运营，我们非常重视您的隐私保护和个人信息保护。为呈现我们处理您个人信息的情况，我们特制定《隐私政策》（以下简称“本隐私政策”），本隐私政策将告诉您我们如何收集和使用有关您的信息，以及我们如何保护这些信息的安全，我们承诺严格按照本隐私政策处理您的个人信息。</div>
      <div className='sectionText'>在您注册登录前，请您务必认真阅读本隐私政策，充分理解各条款内容，包括但不限于免除或限制我们责任的条款。您知晓并确认，当您点击“同意”本隐私政策且使用，就表示您同意我们按照本隐私政策处理您的个人信息。</div>
      <h3>一、我们可能收集的用户信息</h3>
      <div className='sectionText'>我们提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。</div>
      <div className='sectionText'>1.1 您在注册及登录时提供的信息</div>
      <div className='sectionText'>您在注册账户或使用我们的服务时，向我们提供的相关个人信息，例如手机号码、证件号码、邮箱等，若您拒绝提供上述信息，可能会导致您无法注册或登录及使用我们的网站服务。</div>
      <div className='sectionText'>1.2 您在接受我们网站服务时提供的信息</div>
      <div className='sectionText'>当您申请报名、留言区互动时，我们可能会向您收集包括但不限于以下信息：</div>
      <div className='sectionText'>(1) 项目基本信息，包括但不限于名称、logo、图片、视频、路演ppt等；</div>
      <div className='sectionText'>(2) 项目进展情况，包括但不限于公司名称、营业执照、公司营业情况、公司获得投资信息、公司的知识产权信息等；</div>
      <div className='sectionText'>(3) 项目团队成员，包括但不限于团队成员姓名、国籍、手机号码、电子邮箱、最高学历、所在院系专业等；</div>
      <div className='sectionText'>此外，我们可能会接收并记录您的浏览器和计算机、移动设备等设备相关信息，这些信息包括：</div>
      <div className='sectionText'>(1) IP 地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征等信息；</div>
      <div className='sectionText'>(2) 通过 GPS、蓝牙或 WiFi 信号接入点、蓝牙和基站传感器信息等获得的位置信息；</div>
      <div className='sectionText'>(3) 当您使用我们的网站提供的产品或服务时，我们会自动收集您对我们产品或服务的详细使用情况，作为有关网络日志保存。</div>
      <div className='sectionText'>我们承诺不会在您未使用我们产品或服务的情况下额外收集您的个人信息。</div>
      <div className='sectionText'>1.3 我们获取的您的个人信息</div>
      <div className='sectionText'>您在使用平台时，我们收集、汇总、记录的个人信息，包括但不限于竞赛评选结果、投资人感兴趣的项目等。</div>
      <h3>二、我们如何使用用户信息</h3>
      <div className='sectionText'>2.1 在我们提供服务时，用于身份验证、竞赛服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；</div>
      <div className='sectionText'>2.2 帮助我们设计新服务，改善我们现有服务；</div>
      <div className='sectionText'>2.3 使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求；</div>
      <div className='sectionText'>2.4 软件认证或管理软件升级；</div>
      <div className='sectionText'>2.5 我们不会向任何无关第三方复制、分享、提供、处理及披露您的个人信息，但以下情况除外：</div>
      <div className='sectionText'>1. 事先得到您的许可；</div>
      <div className='sectionText'>2. 如果我们作为非专业的普通认识，基于诚信及合理判断认为您出现违反法律法规或者严重违反网站相关协议规则的行为，出于保护其他用户或公众的人身财产安全免遭侵害的需要的情况下披露关于您的个人信息；</div>
      <div className='sectionText'>3. 您自行向社会公众公开的个人信息；</div>
      <div className='sectionText'>4. 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</div>
      <div className='sectionText'>5. 根据法律法规规定，或按政府主管部门的强制性要求，如：</div>
      <div className='sectionText'>(1) 与国家安全、国防安全直接相关的；</div>
      <div className='sectionText'>(2) 与公共安全、公共卫生、重大公共利益直接相关的；</div>
      <div className='sectionText'>(3) 与犯罪侦查、起诉、审判和判决执行等直接相关的</div>
      <div className='sectionText'>(4) 出于维护您或其他个人的生命、财产等重大合法权益的。</div>
      <div className='sectionText'>2.6 不视为复制、分享、提供及披露的行为</div>
      <div className='sectionText'>根据法律规定，共享、转让、公开披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的共享、转让及公开披露行为，对此类数据的保存及处理无需另行向您通知并征得同意。</div>
      <div className='sectionText'>2.7 我们亦不允许任何第三方用户以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何我们平台用户如从事上述活动，一经发现，我们有权立即终止与该用户的服务协议。</div>
      <h3>三、我们如何保护您的信息</h3>
      <div className='sectionText'>3.1 我们高度重视用户个人信息的储存和保护，我们收集的有关您的信息和资料将保存在中国境内服务器上，这些信息和资料可能传送至您所在国家、地区或本应用收集信息和资料所在地的境外并在境外被访问、存储和展示。</div>
      <div className='sectionText'>3.2 平台仅在本《隐私政策》所述目的所必需期间和法律法规及监管规定的时限内保存您的个人信息。如平台终止服务或运营，将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理，法律法规或监管部门另有规定的除外。</div>
      <div className='sectionText'>3.3 我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内。</div>
      <div className='sectionText'>3.4 安全事件处置</div>
      <div className='sectionText'>在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、短信、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</div>
      <div className='sectionText'>请您理解，由于技术的限制以及风险防范的局限，即便我们已经尽量加强安全措施，也无法始终保证个人信息百分之百的安全。您需要了解，您接入平台所用的系统和通讯网络，有可能因我们可控范围外的情况而发生问题。</div>
      <h3>四、免责申明</h3>      
      <div className='sectionText'>4.1 您理解并同意，相关服务可能会受多种因素的影响或干扰，我们不保证 (包括但不限于)：</div>
      <div className='sectionText'>(1) 我们完全适合用户的使用要求；</div>
      <div className='sectionText'>(2) 我们不受干扰，及时、安全、可靠或不出现错误；您经由我们取得的任何软件、服务或其他材料符合您的期望；</div>
      <div className='sectionText'>(3) 软件中任何错误都将能得到更正。</div>
      <div className='sectionText'>4.2 我们可能随时修订本隐私政策。未经您明确同意，我们不会削减您按照相关法律法规及本隐私政策所应享有的权利。更新后的隐私政策将适用于双方之间，无论我们是否已经向您发送有关变更的具体通知。请您定期查看本网页，以了解隐私政策的更新情况。您对修改后的隐私政策有异议的，请立即停止登录、使用相关服务。我们还会将本政策的旧版本存档，供您查阅。</div>
    </div>
  )

  renderText = (type) => {
    const textConfig = {
      register: this.renderRegister(),
      user: this.renderUser(),
      service: this.renderService()
    }
    return textConfig[type] || null
  }

  render() {
    const { pageType } = this.state;
    return (
      <div className="enterPage">
        <Card title={<span className="cardTitle"><h2>{titleConfig[pageType]}</h2></span>}>
          {this.renderText(pageType)}
          {(pageType === 'register' && (
            <Button
              style={{ display: "block", margin: "0 auto" }}
              type="primary"
              onClick={this.hasRead}
            >
              我已阅读
            </Button>
          )) || null}
        </Card>
      </div>
    );
  }
}

export default NeedKnow;
