import React, { useState, useEffect } from 'react';
import { Menu, message } from 'antd'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getDetail, getList } from '../../store/action/news';
import { convertToHtml } from '../../utils/utils'
import { fileUrl } from '../../utils/config'
import download from 'ly-downloader'
import './index.less'

const Explain = (props) => {
  const { introList, dispatch, detail } = props;
  const [menuKey, setMenuKey] = useState('')

  useEffect(() => {
    getList({ type: 1 }, dispatch)
  }, [])

  useEffect(() => {
    if (introList.length > 0) {
      setMenuKey(String(introList[0].news_id))
    }
  }, [introList])

  const menuClick = (menu) => {
    const { key } = menu
    setMenuKey(key)
    getDetail(key, dispatch)
    window.scrollTo(0, 0)
  }

  const downLoadFile = (val) => {
    // download={v.fileName} key={v.file_id} href={`${fileUrl}/${v.file_id}`}
    const ua = navigator.userAgent.toLowerCase();
    if (ua.includes('micromessenger')) {
      message.warning('当前浏览器暂不支持下载附件，请用其它浏览器打开')
    } else {
      download(1, `${fileUrl}/${val.file_id}`, val.fileName)
    }
  }

  return (
    <div className='explain'>
      <div className="menu">
        <Menu
          selectedKeys={[menuKey]}
          onClick={menuClick}
        >
          {introList.map(v => <Menu.Item style={{ height: 52, lineHeight: '52px' }} key={v.news_id}>{v.title}</Menu.Item>)}
        </Menu>
      </div>
      <div className="content">
        <div className="html" dangerouslySetInnerHTML={{ __html: convertToHtml(detail.detail) }}></div>
        <div className="detail-files">{(detail.files || []).map(v => <p key={v.file_id} onClick={() => downLoadFile(v)} >{v.fileName}</p>)}</div>
      </div>
    </div>
  )
}

export default withRouter(connect(state => state.news)(Explain))