import React from 'react';
import moment from 'moment';
import './index.less'

const MyMessage = (props) => {
  const detail = (props.home && props.home.registrationDetail) || {}
  const date = detail.registDate? moment(detail.registDate).format('YYYY-MM-DD HH:mm') : ''
  return detail.registDate? (
    <div className="myRegister">
      <div className="messageTitle">报名成功</div>
      <div className="messageInfo">
        <div>您已成功报名华中科技大学首届全球校友创新创业大赛</div>
        <div>{date}</div>
      </div>
      <div className="divider" />
    </div>
  ) : null
}

export default MyMessage