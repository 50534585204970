import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Redirect } from "react-router";
import { message } from 'antd'
import Layout from "../components/Layout/index";
import Home from "./home";
import NewDetail from "./news/detail";
import EnterPage from "./enterPage";
import KeedKnow from "./enterPage/needKnow";
import Login from "./login";
import PersonCener from "./personalCenter";
import RegisterDetail from './personalCenter/registerDetail'
import Tutor from './tutor/index'
import NewsList from './news'
import HomeDynamic from './home/dynamic'
import Explain from './enterPage/explain'
import { getSession } from '../store/action/home'
import { logout } from '../store/action/home'
import TutorDetail from "./tutor/detai";
import VentureCapital from './ventureCapital'

@withRouter
@connect((state) => state)
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    getSession(dispatch)
    // if (window && window.navigator && window.navigator.userAgent) {
    //   let isMobile =
    //     console.log(window.navigator.userAgent)
    // }
    console.log(window.navigator)
  }

  onChangeStatus = async key => {
    const { history, dispatch } = this.props;
    if (key === 'personal') {
      history.push('/personal')
    } else if (key === 'logout') {
      const res = await logout(dispatch)
      if (res) {
        const { logout } = res.data || {}
        const { code, msg } = logout || {}
        if (code === 200) {
          message.success('已退出登录')
          history.push('/home')
        } else {
          message.error(msg)
        }
      }
    }
  }

  render() {
    const { history, location } = this.props;
    return (
      <Layout onClick={(v) => history.push(`/${v.key}`)} onChangeStatus={this.onChangeStatus} {...this.props}>
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/home" exact component={Home}></Route>
          <Route path="/login" component={Login} key={location.pathname + location.search}></Route>
          <Route path="/dynamic" exact component={NewsList}></Route>
          <Route path="/dynamic/detail/:id" component={NewDetail}></Route>
          <Route path="/enter" component={EnterPage}></Route>
          <Route path="/needKnow" component={KeedKnow}></Route>
          <Route path="/personal" component={PersonCener}></Route>
          <Route path="/registerInfo" component={RegisterDetail}></Route>
          <Route path="/tutor" exact component={Tutor}></Route>
          <Route path="/tutor/detail/:id" component={TutorDetail}></Route>
          <Route path="/intro" component={Explain}></Route>
          <Route path="/vc" exact component={VentureCapital}></Route>
        </Switch>
      </Layout>
    );
  }
}

export default Index;
