import React, { Component } from "react";
import {
  Steps,
  Card,
  Form,
  Input,
  Button,
  message,
  Select,
  Cascader,
  Radio,
  Checkbox,
  DatePicker,
  Space,
} from "antd";
import SinglePictureUpload from "../../components/SinglePictureUpload";
import PictureUpload from "../../components/PictureUpload";
import FileUpload from "../../components/FileUpload";
import config from "./config";
import Investment from "./component/Investment";
import Patent from "./component/patent";
import Member from "./component/member";
import moment from "moment";
import {
  prjStatusConf,
  hasPatentConf,
  investConf,
  supportConf,
  currencyType,
  groupTypeConf,
  regionConf,
  fieldConf,
  isPrivacyConf,
  AFTERTODAY,
  xyhConf,
} from "../../utils/config";
import globalAreaJSON from "../../utils/area.json";
import {
  getRegistrationDetail,
  submit,
  getAllColleges,
} from "../../store/action/home";
import { connect } from "react-redux";
import "./index.less";

const FormItem = Form.Item;
const FormList = Form.List;
const { Step } = Steps;
const { TextArea } = Input;
const { Option } = Select;

const StepArr = ["项目基本信息", "项目进展", "团队成员", "报名参赛"];
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};
const wrapperCol = {
  xs: { span: 24, offset: 0 },
  sm: { span: 16, offset: 10 },
};

const wrapperCols = {
  xs: { span: 24, offset: 0 },
  sm: { span: 16, offset: 4 },
};

const prjAreaConf = fieldConf.map((v) => ({ label: v.name, value: v.id }));

@connect((state) => state.home)
class EnterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      formValues: {},
      initValues: {},
    };
    this.form = React.createRef();
  }

  async componentDidMount() {
    const { dispatch, history } = this.props;
    const resObj = await getRegistrationDetail(dispatch);
    if (resObj.code && resObj.code === 205) {
      message.warning("登录状态已过期");
      setTimeout(() => {
        history.push("/home");
      }, 1500);
      return;
    }
    getAllColleges(dispatch);
    const res = resObj.data ? resObj.data.registrationDetail || {} : {};
    this.setState({ initValues: res });
    const initValues = {
      ...res,
      area: res.area ? res.area.split(",") : null,
      establishmentDate: res.establishmentDate
        ? moment(res.establishmentDate, "YYYY-MM-DD")
        : null,
      incomeType: res.incomeType || 1,
      financings:
        res.financings && res.financings.length > 0
          ? res.financings.map((v) => ({
              ...v,
              getDate: v.getDate ? moment(v.getDate, "YYYY-MM-DD") : null,
            }))
          : [{ amountType: 1 }],
      patents:
        res.patents && res.patents.length > 0
          ? res.patents.map((v) => ({
              ...v,
              publishedAt: v.publishedAt
                ? moment(v.publishedAt, "YYYY-MM-DD")
                : null,
            }))
          : [{}],
      teams:
        res.teams && res.teams.length > 0
          ? res.teams.map((v) => ({
              ...v,
              enterDate: v.enterDate ? moment(v.enterDate, "YYYY-MM") : null,
            }))
          : [{}],
      unitNameS: res.progress === 3 ? res.unitName : null,
      isFinanceS: res.progress === 3 ? res.isFinance : null,
      unitName: res.progress === 3 ? null : res.unitName,
      isFinance: res.progress === 3 ? null : res.isFinance,
    };
    this.form.current.setFieldsValue(initValues);
  }

  goback = () => {
    const { current } = this.state;
    const { history } = this.props;
    if (current !== 0) {
      (window || document.body || document.documentElement).scrollTo({
        top: 0,
      });
      this.setState({
        current: current - 1,
      });
    } else {
      history.goBack();
    }
  };

  // 去掉特殊字符
  formatValue = (data) => {
    const resData = { ...(data || {}) };
    // const inputKey = ['name'];
    ["name", "summary", "videoUrl", "unitName"].forEach((key) => {
      if (data[key]) {
        resData[key] = data[key].replace(/[\x00-\x08\x0b-\x0c\x0e-\x1f]/g, "");
      }
    });
    [
      { key: "patents", keys: ["name", "applicationNo"] },
      { key: "teams", keys: ["name", "contactNumber", "email"] },
      { key: "financings", keys: ["investor", "round", "amount"] },
    ].forEach((ele) => {
      const { key, keys } = ele;
      if (data[key] && data[key].length) {
        resData[key] = data[key].map((item) => {
          const itemData = { ...item };
          keys.forEach((key) => {
            if (item[key]) {
              itemData[key] = item[key]
                .replace(/[\x00-\x08\x0b-\x0c\x0e-\x1f]/g, "")
				.replace(/[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig, '')
                // .replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g, "");
            }
          });
          return itemData;
        });
      }
    });
    return resData;
  };

  handleSubmit = async () => {
    const { current, formValues } = this.state;
    const { registrationDetail, history } = this.props;
    const { registDate } = registrationDetail;
    const fieldsValue = await this.form.current.validateFields();
    if (fieldsValue) {
      if (current < 3) {
        (window || document.body || document.documentElement).scrollTo({
          top: 0,
        });
        this.setState({
          current: current + 1,
          formValues: {
            ...formValues,
            [current]: fieldsValue,
          },
        });
        if (!registDate) {
          // 初始化数据
          const formatValues = {};
          if (current === 0) {
            formatValues.area = fieldsValue.area
              ? fieldsValue.area.join(",")
              : null;
          }
          if (current === 1 && fieldsValue.financings) {
            formatValues.financings = fieldsValue.financings.map((v) => ({
              ...v,
              amountType: v.amountType || 1,
            }));
            formatValues.unitName =
              fieldsValue.progress === 3
                ? formatValues.unitNameS
                : formatValues.unitName;
            formatValues.isFinance =
              fieldsValue.progress === 3
                ? formatValues.isFinanceS
                : formatValues.isFinanceS;
          }
          delete formatValues.unitNameS;
          delete formatValues.isFinanceS;
          const initFieldsValue = this.formatValue({
            ...fieldsValue,
            ...formatValues,
          });

          const resSub = await submit({
            registration_id: registrationDetail.registration_id,
            ...initFieldsValue,
          });
          if (resSub.code && resSub.code === 205) {
            message.warning("登录状态已过期");
            setTimeout(() => {
              history.push("/home");
            }, 1500);
          }
        }
      } else {
        let initRegistDate = new Date();
        if (registDate) {
          initRegistDate = undefined;
        }
        const formatValues = {
          area: formValues[0].area ? formValues[0].area.join(",") : null,
          financings: (formValues[1].financings || []).map((v) => ({
            ...v,
            amountType: v.amountType || 1,
          })),
          unitName:
            formValues[1].progress === 3
              ? formValues[1].unitNameS
              : formValues[1].unitName,
          isFinance:
            formValues[1].progress === 3
              ? formValues[1].isFinanceS
              : formValues[1].isFinance,
        };

        delete formValues[1].unitNameS;
        delete formValues[1].isFinanceS;

        const initFieldsValue = this.formatValue({
          ...formValues[0],
          ...formValues[1],
          ...formValues[2],
          ...fieldsValue,
          ...formatValues,
        });

        const res = await submit({
          registration_id: registrationDetail.registration_id,
          ...initFieldsValue,
          registDate: initRegistDate,
        });
        if (res) {
          if (res.code === 205) {
            message.warning("登录状态已过期");
            setTimeout(() => {
              history.push("/home");
            }, 1500);
            return;
          }
          const { updateRegistration } = res.data || {};
          if (updateRegistration.code === 200) {
            message.success(registDate ? "修改成功" : "报名成功");
            history.push("/personal");
          } else {
            message.error(updateRegistration.msg);
          }
        }
      }
    }
  };

  onFormValuesChange = (e) => {
    const { initValues } = this.state;
    const { setFieldsValue } = this.form.current;
    if (e.region) {
      setFieldsValue({
        alumniAssociation: null,
      });
    }
    this.setState({
      initValues: {
        ...initValues,
        ...e,
      },
    });
  };

  // filter = (inputValue, path) => {
  //     return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  // }

  render() {
    const { current, initValues } = this.state;
    const { colleges } = this.props;
    // const { registrationDetail } = this.props;
    const xyhArr =
      (xyhConf.find((v) => v.sq === initValues.region) || {}).xyh || [];
    return (
      <div className="enterPage">
        <Card
          title={<span className="cardTitle">报名信息</span>}
          extra={
            <div>
              下载 <a href="/报名信息模板.docx">报名信息模板</a>，了解填报内容项
            </div>
          }
        >
          <Steps current={current}>
            {StepArr.map((v) => (
              <Step title={v} key={v} />
            ))}
          </Steps>
          <div className="formBox">
            <Form
              {...formItemLayout}
              onFinish={this.handleSubmit}
              ref={this.form}
              onValuesChange={this.onFormValuesChange}
            >
              {current === 0 && (
                <>
                  <FormItem label="项目logo" name="logo" {...config.logo}>
                    <SinglePictureUpload />
                  </FormItem>
                  <FormItem label="项目名称" name="name" {...config.name}>
                    {<Input placeholder="请输入项目名称" />}
                  </FormItem>
                  <FormItem
                    label="公司或项目团队所在地"
                    name="area"
                    {...config.area}
                  >
                    <Cascader
                      placeholder="请选择"
                      options={globalAreaJSON}
                      fieldNames={{
                        label: "name",
                        value: "name",
                        children: "sublist",
                      }}
                      // showSearch={{ filter: this.filter }}
                    />
                  </FormItem>
                  <FormItem label="项目概述" name="summary" {...config.summary}>
                    <TextArea rows={4} placeholder="请输入项目概述" />
                  </FormItem>
                  <FormItem
                    label="项目展示图片"
                    name="pictures"
                    {...config.pictures}
                  >
                    <PictureUpload />
                  </FormItem>
                  <FormItem
                    label="项目展示视频"
                    name="videoUrl"
                    {...config.videoUrl}
                  >
                    <Input placeholder="粘贴视频链接，如优酷、哔哩哔哩等" />
                  </FormItem>
                  <FormItem label="项目路演PPT" name="ppt" {...config.ppt}>
                    <FileUpload />
                  </FormItem>
                </>
              )}
              {current === 1 && (
                <>
                  <FormItem
                    label="项目进展"
                    name="progress"
                    {...config.progress}
                  >
                    <Select placeholder="请选择">
                      {prjStatusConf.map((v) => (
                        <Option key={v.id} value={v.id}>
                          {v.name}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                  {initValues.progress === 2 && (
                    <>
                      <FormItem
                        label="公司名称"
                        name="unitName"
                        {...config.unitName}
                      >
                        <Input placeholder="请输入公司名称" />
                      </FormItem>
                      <FormItem
                        label="工商登记时间"
                        name="establishmentDate"
                        {...config.establishmentDate}
                      >
                        <DatePicker disabledDate={AFTERTODAY} />
                      </FormItem>
                      <FormItem
                        label="营业执照"
                        name="license"
                        {...config.license}
                      >
                        <SinglePictureUpload />
                      </FormItem>
                      <FormItem
                        label="企业网址"
                        name="website"
                        {...config.website}
                      >
                        <Input placeholder="请输入企业网址" />
                      </FormItem>
                      <FormItem
                        label="2020年底营业收入"
                        name="income"
                        {...config.income}
                      >
                        <Input
                          placeholder="请输入金额"
                          style={{ width: 250 }}
                          type="number"
                          addonAfter={
                            <FormItem
                              style={{ marginBottom: "-2px" }}
                              name="incomeType"
                            >
                              <Select style={{ width: 100 }}>
                                {currencyType.map((v) => (
                                  <Option value={v.id} key={v.id}>
                                    {v.name}
                                  </Option>
                                ))}
                              </Select>
                            </FormItem>
                          }
                        />
                      </FormItem>
                      <FormItem
                        label="获得投资情况"
                        name="isFinance"
                        {...config.isFinance}
                      >
                        <Select placeholder="请选择">
                          {investConf.map((v) => (
                            <Option key={v.id} value={v.value}>
                              {v.name}
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                      {initValues.isFinance && (
                        <FormItem wrapperCol={wrapperCols}>
                          <FormList name="financings">
                            {(fields, { add, remove }) => (
                              <Investment
                                fields={fields}
                                add={add}
                                remove={remove}
                                initValues={initValues.financings || []}
                              />
                            )}
                          </FormList>
                        </FormItem>
                      )}
                    </>
                  )}
                  {initValues.progress === 3 && (
                    <>
                      <FormItem
                        label="社会组织名称"
                        name="unitNameS"
                        {...config.unitName}
                      >
                        <Input placeholder="请输入社会组织名称" />
                      </FormItem>
                      <FormItem
                        label="获得资金支持情况"
                        name="isFinanceS"
                        {...config.isFinance}
                      >
                        <Select placeholder="请选择">
                          {supportConf.map((v) => (
                            <Option key={v.id} value={v.value}>
                              {v.name}
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </>
                  )}
                  <FormItem
                    label="是否已获专利"
                    name="isPatent"
                    {...config.isPatent}
                  >
                    <Select placeholder="请选择">
                      {hasPatentConf.map((v) => (
                        <Option key={v.id} value={v.value}>
                          {v.name}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                  {initValues.isPatent === true && (
                    <FormItem wrapperCol={wrapperCols}>
                      <FormList name="patents">
                        {(fields, { add, remove }) => (
                          <Patent fields={fields} add={add} remove={remove} />
                        )}
                      </FormList>
                    </FormItem>
                  )}
                </>
              )}
              <div style={current !== 2 ? { display: "none" } : null}>
                <FormItem wrapperCol={wrapperCols}>
                  <FormList name="teams">
                    {(fields, { add, remove }) => (
                      <Member
                        ref={this.form}
                        colleges={colleges}
                        fields={fields}
                        add={add}
                        remove={remove}
                        initValues={initValues.teams || []}
                      />
                    )}
                  </FormList>
                </FormItem>
              </div>
              {current === 3 && (
                <>
                  <FormItem label="参赛组别" name="group" {...config.group}>
                    <Radio.Group>
                      <Space direction="vertical">
                        {groupTypeConf.map((v) => (
                          <Radio key={v.id} value={v.id}>
                            {
                              <div className="redioItem">
                                <span className="item">{v.name}</span>
                                <span className="extra">{v.extra}</span>
                              </div>
                            }
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  </FormItem>
                  <FormItem label="参赛赛区" name="region" {...config.region}>
                    <Select>
                      {regionConf.map((v) => (
                        <Option key={v.id} value={v.id}>
                          {v.name}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem
                    label="校友会"
                    name="alumniAssociation"
                    {...config.alumniAssociation}
                  >
                    <Select>
                      {xyhArr.map((v) => (
                        <Option key={v} value={v}>
                          {v}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                  <FormItem label="项目领域" name="field" {...config.field}>
                    <Checkbox.Group options={prjAreaConf} />
                  </FormItem>
                  <FormItem
                    label="隐私设置"
                    name="isPrivacy"
                    {...config.isPrivacy}
                  >
                    <Radio.Group>
                      <Space direction="vertical">
                        {isPrivacyConf.map((v) => (
                          <Radio key={v.id} value={v.value}>
                            {
                              <div className="redioItem">
                                <span className="item">{v.name}</span>
                                <span className="extra">{v.extra}</span>
                              </div>
                            }
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  </FormItem>
                </>
              )}
              <FormItem wrapperCol={wrapperCol}>
                <div>
                  <Button style={{ marginRight: 20 }} onClick={this.goback}>
                    {current === 0 ? "返回" : "上一步"}
                  </Button>
                  <Button type="primary" htmlType="submit">
                    {current === 3 ? "报名参赛" : "下一步"}
                  </Button>
                </div>
              </FormItem>
            </Form>
          </div>
        </Card>
      </div>
    );
  }
}

export default EnterPage;
