import constant from '../constant/tutor'
import { get } from '../../utils/require'

export const getDetail = async (id, dispatch) => {
  const res = await get(`/api/tutor/detail?tutor_id=${id}`)
  if (res) {
    dispatch({
      type: constant.TUTOR_SAVE,
      payload: { detail: res }
    })
  }
}

export const getList = async (params, dispatch) => {
  const res = await get('/api/tutor/list', params)
  if (res) {
    const tutors = res || {}
    const { count, rows } = tutors
    dispatch({
      type: constant.TUTOR_SAVE,
      payload: {
        list: rows || [],
        count: count || 0,
        searchParams: params
      }
    })
  }
}