import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import './index.less'

function VentureCapital() {
  return (
    <div className="showImg" style={{ backgroundImage: `url('/vc.jpg')` }}></div>
  );
}

export default withRouter(connect(state => state.tutor)(VentureCapital));
