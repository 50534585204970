import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Card, message } from 'antd'
import { getDetail } from '../../store/action/news'
import { fileUrl } from '../../utils/config'
import { convertToHtml } from '../../utils/utils';
import download from 'ly-downloader'
import './index.less'
@connect(state => state.news)
class NewsDetail extends Component {

  componentDidMount() {
    const { dispatch, match: { params: { id } } } = this.props;
    if (id) {
      getDetail(id, dispatch)
    }
    window.scrollTo(0, 0)
  }

  downLoadFile = (val) => {
    // download={v.fileName} key={v.file_id} href={`${fileUrl}/${v.file_id}`}
    const ua = navigator.userAgent.toLowerCase();
    if (ua.includes('micromessenger')) {
      message.warning('当前浏览器暂不支持下载附件，请用其它浏览器打开')
    } else {
      download(1, `${fileUrl}/${val.file_id}`, val.fileName)
    }
  }

  render() {
    const { detail } = this.props
    return (
      <Card className='news-detail'>
        <h1>{detail.title}</h1>
        <div className='date'>发布时间：{detail.issueDate}</div>
        {detail.video && <div className="detail-video">
          <video style={{
            // backgroundImage: `url('/bg_image.png')`,
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: '100% 100%'
          }} src={`${fileUrl}/${detail.video}`} poster={detail.img ? `${fileUrl}/${detail.img}` : ''} controls></video>
        </div>}
        <div className='detail' dangerouslySetInnerHTML={{ __html: convertToHtml(detail.detail) }}></div>
        <div className="detail-files">{(detail.files || []).map(v => <p key={v.file_id} onClick={() => this.downLoadFile(v)} >{v.fileName}</p>)}</div>
        {/* <div className="source">{detail.remark}</div> */}
      </Card>
    )
  }
}

export default NewsDetail;