import { combineReducers } from 'redux'
import home from './home.js'
import user from './user'
import news from './news'
import tutor from './tutor'

const reducer = combineReducers({
    home,
    user,
    news,
    tutor
})
export default reducer;