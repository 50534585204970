import { Card, Carousel } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { newsBlocks } from './config'
import { getInitData } from '../../store/action/home'
import { fileUrl } from '../../utils/config'
import "./index.less";

const carouselProps = {
  autoplaySpeed: 6000,
  autoplay: true,
}

const NewsCard = ({ data, onClick }) => (
  <Card
    className="news-cardnew"
    style={{ cursor: "pointer" }}
    onClick={onClick}
  >
    <h2 className="card-title">{data.title}</h2>
    <div>{data.issueDate}</div>
  </Card>
);

@connect((state) => state)
class Home extends Component {


  componentDidMount() {
    const { dispatch } = this.props;
    getInitData({ page: 1, pageSize: 3 }, dispatch);
  }

  toEnter = () => {
    const { home, history } = this.props;
    if (home.user) {
      history.push("/needKnow");
    } else {
      history.push("/login");
    }
  };

  toNewsDetail = (id) => {
    const { history } = this.props;
    // history.push(`/home/detail/${id}`)
    window.open(`/dynamic/detail/${id}`);
  };

  renderBlock = (data) => {
    const {
      home
    } = this.props;
    const curItem = home[`${data.value}List`][0] || {}
    return (
      <div key={data.value} style={{ width: "31%" }}>
        <div className="title_tubiao">
          <span className="title_text">{data.label}</span>
          <a className="title_more" href={`/dynamic?type=${data.value}`}>
            更多
          </a>
          <span className="title_modal"></span>
        </div>
        <div className="content-img">
          {!curItem.video && <img
            src={curItem.img ? `${fileUrl}/${curItem.img}` : '/bg_default.png'}
            alt="notice"
            onClick={() => this.toNewsDetail(curItem.news_id)}
          />}
          {curItem.video &&
            <video style={{
              // backgroundImage: `url('/bg_image.png')`,
              // backgroundRepeat: 'no-repeat',
              // backgroundSize: '100% 100%'
            }}
              src={`${fileUrl}/${curItem.video}`}
              controls
              poster={curItem.img ? `${fileUrl}/${curItem.img}` : ''}
              onClick={e => e.stopPropagation()}>
            </video>
          }
        </div>
        <div style={{ marginBottom: "20px" }}>
          {home[`${data.value}List`].map(v => <NewsCard
            key={v.news_id}
            data={v}
            onClick={() => this.toNewsDetail(v.news_id)}
          />)}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="home">
        <div>
          <Carousel {...carouselProps}>
          <div>
            <div
              className="baseInfo"
              style={{ backgroundImage: `url('/home_banner.jpg')` }}
              // onClick={this.toEnter}
            />
          </div>
          <div>
            <div
              className="baseInfo"
              style={{ backgroundImage: `url('/vc.jpg')` }}
              // onClick={this.toEnter}
            />
          </div>          
          </Carousel>
        </div>
        <div className="news_module">
          <div className="news_module_content">
            {newsBlocks.map((v) => this.renderBlock(v))}
          </div>
        </div>
        <div className="home_process">
          <div className="normal_width">
            <div className="title_tubiao">
              <span
                className="title_text"
                colorspesial="true"
                style={{ color: "white" }}
              >
                大赛流程
              </span>
              <span className="title_modal" colorspesial="true"></span>
            </div>
            <div>
              <img src="/progress.png" alt="progress" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
