import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Dropdown } from "antd";
import "./index.less";

const { Header, Content, Footer } = Layout;
const navLists = [
  {
    label: "首页",
    value: "home",
  },
  {
    label: '大赛介绍',
    value: 'intro'
  },
  {
    label: "新闻动态",
    value: 'dynamic'
  },
  {
    label: "大赛导师",
    value: 'tutor'
  },
  {
    label: "投资机构",
    value: 'vc'
  }
];

const itemList = ["contactNumber", "address", "phone", "email"];

const content_center_label = {
  contactNumber: "大赛组委会秘书处联系方式",
  address: "地址",
  phone: "电话",
  email: "邮箱",
  qqGroup: "QQ群",
};

const initData = {
  contactNumber: "",
  address: "湖北省武汉市洪山区珞喻路1037号 华中科技大学校友总会",
  phone: "027-87544403 / 027-87542502",
  email: "hustxyzh@hust.edu.cn",
  qqGroup: "788580901",
};

class PageLayout extends Component {
  constructor(props) {
    super(props);
    const {
      location: { pathname },
    } = props;
    const pathArr = pathname.split("/");
    let key = "home";
    if (pathArr[1] && pathArr[1] !== "home") {
      key = pathArr[1];
    }
    this.state = {
      key,
    };
  }

  static getDerivedStateFromProps(nextProps, preState) {
    const {
      location: { pathname },
    } = nextProps;
    const pathArr = pathname.split("/");
    if (pathArr[1] !== preState.key) {
      return {
        key: pathArr[1] || "home",
      };
    }
    return null;
  }

  render() {
    const { children, onClick, home, onChangeStatus } = this.props;
    const { key } = this.state;
    const menu = (
      <Menu onClick={(e) => onChangeStatus(e.key)}>
        <Menu.Item key="personal">个人中心</Menu.Item>
        <Menu.Item key="logout">退出登录</Menu.Item>
      </Menu>
    );
    return (
      <Layout className="layout">
        <Header className="header">
          <div className="persen">
            {!home.user && (
              <>
                <Link className="navList" to="/login">
                  登录
                </Link>
                <Link className="navList" to="/login?type=register">
                  注册
                </Link>
              </>
            )}
            {home.user && (
              <Dropdown
                overlayClassName="dropdown-style"
                overlay={menu}
                onClick={(e) => e.preventDefault()}
              >
                <div className="textElli">{(home.curUser && home.curUser.name) || '**'}</div>
              </Dropdown>
            )}
          </div>
          <div className="menu">
            <div className="logo" onClick={() => onClick({ key: "home" })}>
              <img src="/logo1.png" alt="logo" />
            </div>
            <Menu theme="" mode="horizontal" selectedKeys={[key]} onClick={v => onClick(v)}>
              {navLists.map(v => <Menu.Item key={v.value} className={v.value === key ? 'curItem' : null}>{v.label}</Menu.Item>)}
            </Menu>
          </div>
        </Header>
        <Content className="content">{children}</Content>
        <Footer className="footer">
          <div className="footer_content">
            <div className="logo">
              <img src="/logo2.png" alt="logo" />
            </div>
            <div className="content_center">
              {/* <ul>
                                <li>ICPxxxxxx号</li>
                                <li>版权归属：华中科技大学</li>
                            </ul> */}
              <ul style={{ borderLeft: "1px solid #666666" }}>
                {itemList.map((v) => (
                  <li key={v}>
                    <label>{content_center_label[v]}</label>：
                    <span>{initData[v]}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="erweima">
              <img src="/erweima.png" alt="erweima" />
              <span>官网微信平台</span>
            </div>
          </div>
        </Footer>
      </Layout>
    );
  }
}

export default PageLayout;
