import React, { Component } from 'react'
import { Input, DatePicker, Button, Select, Form } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import {
  currencyType,
  AFTERTODAY
} from '../../../utils/config'
import config from '../config'
import './index.less'

const { Option } = Select;
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const formItemLayoutWithoutLabel = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16, offset: 8 },
  },
};
class Investment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itemStatus: {}
    }
  }

  render() {
    const { fields, add, remove } = this.props;
    return (
      <div>
        {fields.map(({ key, name, fieldKey, ...restField }) =>
          <div key={key} className="card">
            <FormItem wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 6 },
            }} {...restField} style={{ width: '84%', marginBottom: 0 }}>
              <FormItem style={{ display: 'none' }} fieldKey={[fieldKey, 'financing_id']} name={[name, 'financing_id']} >
              </FormItem>
              <FormItem {...formItemLayout} label='获得时间' fieldKey={[fieldKey, 'getDate']} name={[name, 'getDate']} {...config.invest_getDate}>
                <DatePicker disabledDate={AFTERTODAY} />
              </FormItem>
              <FormItem {...formItemLayout} label='投资机构' fieldKey={[fieldKey, 'investor']} name={[name, 'investor']} {...config.invest_investor}>
                <Input placeholder='请输入投资机构' />
              </FormItem>
              <FormItem {...formItemLayout} label='融资轮次' fieldKey={[fieldKey, 'round']} name={[name, 'round']} {...config.invest_round}>
                <Input placeholder='请输入融资轮次' />
              </FormItem>
              <FormItem {...formItemLayout} label='融资金额' fieldKey={[fieldKey, 'amount']} name={[name, 'amount']} {...config.invest_amount}>
                <Input placeholder='请输入融资金额' type="number" addonAfter={
                  <FormItem style={{ marginBottom: '-2px' }} fieldKey={[fieldKey, 'amountType']} name={[name, 'amountType']}>
                    <Select style={{ width: 100 }} defaultValue={1}>
                      {currencyType.map(v => <Option value={v.id} key={v.id}>{v.name}</Option>)}
                    </Select>
                  </FormItem>} />
              </FormItem>
              <FormItem {...formItemLayoutWithoutLabel} >
                <span style={{ color: '#1890ff', marginRight: '20px', cursor: 'pointer' }} onClick={() => add({}, name + 1)}><PlusCircleOutlined style={{ color: '#1890ff', fontSize: 18, marginRight: '5px' }} type="dashed" />添加</span>
                {name > 0 && <span style={{ color: '#1890ff', cursor: 'pointer' }} onClick={() => remove(name)} ><MinusCircleOutlined style={{ color: '#1890ff', fontSize: 18, marginRight: '5px' }} />删除</span>}
              </FormItem>
            </FormItem>
          </div>
        )
        }
      </div>
    )
  }
}

export default Investment;