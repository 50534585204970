const Message = ` code msg id`

const RegistrationDetail = `
    registration_id
    user_id
    logo
    name
    area
    summary
    pictures
    videoUrl
    ppt
    progress
    unitName
    establishmentDate
    license
    website
    income
    incomeType
    isFinance
    isPatent
    group
    region
    alumniAssociation
    field
    isPrivacy
    registDate
    updatedAt
    financings{
        getDate
        investor
        round
        amount
        amountType
    }
    patents{
        name
        type
        applicationNo
        publishedAt
    }
    teams{
        name
        nationality
        contactNumber
        email
        isAlumnus
        highestUniversity
        highestEducation
        studentStatus
        education
        faculty
        major
        tutor
        enterDate
        isFounder
        takeOffice
        job
        ratio
        isTeacher
    }

`;

const getSession = {
  query: `
    query{
        getSession{
            user{
                user_id
                name
                contactNumber
                email
            }
        }
        getUser{
          user_id
          name
          contactNumber
          telephoneCode
          email
          cardType
          cardNum
        }
    }
    `,
};

const getRegistrationDetail = {
  query: `
    query{
        registrationDetail{
            ${RegistrationDetail}
        }
    }
    `,
};

const updateRegistration = {
  query: `
    mutation($input:UpdateRegistration){
        updateRegistration(input:$input){
            code
            msg
        }
    }
    `,
};

const logout = {
  query: `
    mutation{
        logout{
            code
            msg
        }
    }
    `,
};

const getCountries = {
  query: `
    query($input:UnitArgs){
        countries(input:$input){
            id
            name
            nameCN
            native
        }
    }
    `,
};

const getStates = {
  query: `
    query($input:UnitArgs){
        states(input:$input){
            id
            name
            nameCN
            native
        }
    }
    `,
};

const getCities = {
  query: `
    query($input:UnitArgs){
        cities(input:$input){
            id
            name
            nameCN
            native
        }
    }
    `,
};

const getColleges = {
  query: `
    query($input:UnitArgs){
        colleges(input:$input){
            id
            name
            nameCN
            native
        }
    }
    `,
};

const getUser = {
  query: `
        query {
            getUser {
                user_id
                name
                contactNumber
                telephoneCode
                email
                cardType
                cardNum
            }
        }
    `,
};

const updateUser = data => {
  return {
    query: `
      mutation($input: UpdateUser) {
        updateUser(input: $input) {
          ${Message}
        }
      }
    `,
    variables: { input: data || {} }
  }
}

const updatePassword = data => {
  return {
    query: `
      mutation($input: UpdatePassword) {
        updatePassword(input: $input) {
          ${Message}
        }
      }
    `,
    variables: { input: data || {} }
  }
}

const index = {
  getSession,
  getRegistrationDetail,
  updateRegistration,
  logout,
  getCountries,
  getStates,
  getCities,
  getColleges,
  getUser,
  updateUser,
  updatePassword
};

export default index;
