
const initState = {

}
const user = (state = initState, action) => {
    switch (action.type) {
        case 'addUser':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}
export default user;