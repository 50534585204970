import constant from '../constant/news'
const initState = {
    introList: [],
    introCount: 0,
    introSearchParams: {},
    dynamicList: [],
    dynamicCount: 0,
    dynamicSearchParams: { page: 1, pageSize: 5, type: 2 },
    newsList: [],
    newsCount: 0,
    newsSearchParams: { page: 1, pageSize: 5, type: 3 },
    noticeList: [],
    noticeCount: 0,
    noticeSearchParams: { page: 1, pageSize: 5, type: 4 },
    eastList: [],
    eastCount: 0,
    eastSearchParams: { page: 1, pageSize: 5, type: 5, zone: 1 },
    southList: [],
    southCount: 0,
    southSearchParams: { page: 1, pageSize: 5, type: 5, zone: 2 },
    westList: [],
    westCount: 0,
    westSearchParams: { page: 1, pageSize: 5, type: 5, zone: 3 },
    northList: [],
    northCount: 0,
    northSearchParams: { page: 1, pageSize: 5, type: 5, zone: 4 },
    centerList: [],
    centerCount: 0,
    centerSearchParams: { page: 1, pageSize: 5, type: 5, zone: 5 },
    overseasList: [],
    overseasCount: 0,
    overseasSearchParams: { page: 1, pageSize: 5, type: 5, zone: 6 },
    detail: {}
}
const news = (state = initState, action) => {
    switch (action.type) {
        case constant.NEWS_DETAIL:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}
export default news;