import constant from '../constant/tutor'
const initState = {
  list: [],
  count: 0,
  detail: {},
  searchParams: {
    page: 1,
    pageSize: 12
  }
}
const news = (state = initState, action) => {
  switch (action.type) {
    case constant.TUTOR_SAVE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
}
export default news;