import React, { Component } from 'react';
import { Upload, message, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { formatFileList, urlPre } from '../utils/utils'

@withRouter
class UploadCom extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            fileList: [],
            previewVisible: false,
            previewTitle: '',
            previewImage: ''
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value && props.value.length > 0 && state.fileList.length === 0) {
            return {
                fileList: formatFileList(props.value)
            }
        }
        return null
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = file => {
        this.setState({
            previewImage: file.url || (file.response && `${urlPre}/${file.response.data.file_id}`),
            previewVisible: true,
            previewTitle: file.name
        });
    };

    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('只能上传 JPG/PNG 文件!');
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error('最大不超过 10MB!');
        }
        return isJpgOrPng && isLt10M;
    }

    handleChange = (info) => {
        const { onChange, history } = this.props;
        if (!info.file.status) return;
        if (info.file.status !== 'uploading') {
            const initFileList = info.fileList.map(v => {
                if (v.response) {
                    if (v.response.code === 205) {
                        message.warning('登录状态已过期')
                        setTimeout(() => {
                            history.push('/home')
                        }, 1500);
                        return {};
                    }
                    return {
                        name: v.name,
                        file_id: v.response.data.file_id,
                    }

                }
                const urlArr = v.url ? v.url.split(`${urlPre}/`) : []
                const url = urlArr.length > 0 ? urlArr[1] : ''
                return {
                    name: v.name,
                    file_id: url
                }
            })
            onChange(initFileList)
        }
        this.setState({
            fileList: info.fileList
        })
    };

    render() {
        const {
            loading,
            fileList,
            previewVisible,
            previewTitle,
            previewImage
        } = this.state;
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>上传</div>
            </div>
        );
        const initProps = {
            action: "/api/file/upload",
            onChange: this.handleChange,
            beforeUpload: this.beforeUpload,
            listType: "picture-card",
            onPreview: this.handlePreview,
            // accept: '.png, .jpeg, .jpg',
            fileList
        }
        return (
            <div>
                <Upload
                    {...initProps}
                >
                    {fileList.length > 8 ? null : uploadButton}
                </Upload >
                <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>

        )
    }
}

export default UploadCom;