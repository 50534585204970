import { useState, useEffect } from 'react'
import { Select, Input } from 'antd'

const SelectWithOther = ({ onChange, value, placeholder, showSearch, onSearch, children }) => {
    const [showOther, setShowOther] = useState(false)
    useEffect(() => {
        const showOther = value !== null && children[0] && !children[0].find(v => v.props.value === value) ? true : false
        setShowOther(showOther)
    }, [value])

    const handleChange = (e) => {
        if (e === '其他') {
            setShowOther(true)
            onChange('')
        } else {
            setShowOther(false)
            onChange(e)
        }
    }

    const onInputChange = (e) => {
        onChange(e.target.value)
    }

    const searchProps = {
        showSearch: !!showSearch
    }
    if (showSearch) {
        searchProps.filterOption = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    return (
        <div>
            <Select
                placeholder='请选择'
                showSearch={showSearch}
                value={showOther ? '其他' : value} onChange={handleChange}
                onSearch={onSearch}
                {...searchProps}
            >
                {children}
            </Select>
            {showOther && <Input style={{ marginTop: '10px' }} placeholder={placeholder} value={showOther ? value : ''} onChange={onInputChange} />}
        </div>
    )
}

export default SelectWithOther