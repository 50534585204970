
export const curYear = new Date().getFullYear();
export const curMonth = new Date().getMonth() + 1;
export const curDay = new Date().getDate();
export const curWeek = new Date().getDay();

export const getUrlSearchObj = url => {
  const res = {}
  const target = (url || '').split('?')
  if (target[1]) {
    target[1].split('&').forEach(ele => {
      if (ele && ele.indexOf('=') > 0) {
        const arr = ele.split('=')
        res[arr[0]] = arr[1]
      }
    })
  }
  return res
}

export const urlPre = '/api/file'

// 将数据库数据结构 转为upLoad数据结构
export const formatFileList = arr => {
  if (!arr || !Array.isArray(arr)) return []
  return arr.map(v => ({
    uid: v.file_id,
    url: `${urlPre}/${v.file_id}`,
    name: v.name,
    status: 'done'
  }))
}

export const formatTreeData = (arr, id, p_id) => {
  const fathObj = {}
  arr.forEach(v => {
    if (!fathObj[p_id]) {
      fathObj[v[p_id]] = {
        [p_id]: v[p_id]
      }
    }
  })
  arr.forEach(v => {
    const curChild = fathObj[v[p_id]][id];
    if (curChild) {
      if (!curChild.includes(v[id])) {
        fathObj[v[p_id]][id].push(v[id])
      }
    } else {
      fathObj[v[p_id]][id] = v[id] ? [v[id]] : []
    }
  })
  const res = Object.values(fathObj);
  return res;
}

// 判断是否是对应的数据类型
export const isDataType = (obj, type) => {
  if (!type || !Object.prototype.toString.call(type) === `[object String]`) return false;
  if (type === '2DArray' || type === '1DArray') {
    if (isDataType(obj, 'Array')) {
      const resArr = obj.filter((ele) => isDataType(ele, 'Array'));
      return type === '2DArray' ? !!resArr.length && resArr.length === obj.length : !resArr.length;
    }
    return false;
  }
  if (type.toLowerCase() === 'dom' && Object.prototype.toString.call(obj) === '[object Object]') {
    // console.log(obj)
    return (
      obj.$$typeof &&
      Object.prototype.toString.call(obj.$$typeof) === '[object Symbol]' &&
      obj.$$typeof.toString() === 'Symbol(react.element)'
    );
  }
  let formartType = (type || '').trim().toLowerCase();
  formartType = `${formartType.substring(0, 1).toUpperCase()}${formartType.substring(1)}`;
  return Object.prototype.toString.call(obj) === `[object ${formartType || ''}]`;
};

/**
 * @description 根据属性判断字段是否显示
 * @param {object} data // 数据源
 * @param {object | array} visibleProps // 属性
 * @return {boolean} // 为true时显示
 * @abstract prop标准化为二维数组，第一层为或的关系，第二层为且的关系
 */
export const handleFieldsVisible = (data, formData, visibleProps) => {
  let formartProps = visibleProps;
  if (isDataType(visibleProps, 'Object')) {
    formartProps = [[visibleProps]];
  } else if (isDataType(visibleProps, '1DArray')) {
    formartProps = [visibleProps];
  }
  let result = false;
  if (isDataType(formartProps, '2DArray')) {
    formartProps.forEach((arr) => {
      let firstVisible = true;
      arr.forEach((prop) => {
        let visible = true;
        if (isDataType(prop, 'Object') && prop.visibleKey) {
          const { visibleKey, visibleValue, visibleWay, dataFromForm } = prop;
          const targetValue = dataFromForm ? formData[visibleKey] : data[visibleKey];
          // if (dataFromForm && form && form.getFieldsValue) {
          //   targetValue = form.getFieldsValue([visibleKey]);
          // }
          if (visibleWay === 'equal') {
            visible = String(targetValue) === String(visibleValue);
          } else if (visibleWay === 'noEqual') {
            visible = String(targetValue) !== String(visibleValue);
          } else if (visibleWay === 'beInclude') {
            visible = visibleValue.includes(targetValue)
          } else if (visibleWay === 'include') {
            visible = (targetValue || []).includes(visibleValue)
          }
        }
        if (!visible && !!firstVisible) {
          firstVisible = false;
        }
      });
      if (firstVisible) {
        result = true;
      }
    });
  }

  return result;
};

export const getAllText = (value) => {
  if (value) {
    return value.replace(/<[^>]+>/g, '').replace(/&nbsp;/ig, '').replace(/\s/g, '')
  } else {
    return ''
  }
}

// export const convertToHtml = function (text) {
//   let innerHtml = null;
//   if (text) {
//     const curData = JSON.parse(text);
//     innerHtml = convertRawToHTML(curData);
//   }
//   return innerHtml;
// };

export const convertToHtml = (str) => {
  // console.log(str)
  const imgPre = '/api/file/getExtertalFile?src=';
  if (!str) return '';
  if (!str.startsWith('{')) return str;
  const obj = JSON.parse(str);
  const BraftEditor = require('braft-editor').default;
  const Table = require('braft-extensions/dist/table').default;
  BraftEditor.use(Table())
  const braDom = BraftEditor.createEditorState(obj);
  const html = braDom.toHTML();
  return html.replace(/<p><\/p>/g, '<br />').replace(/<img src="http/g,`<img src="${imgPre}http`)
}