import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { Modal, Input, Form, Select, message } from 'antd';
import graphql from '../../graphql/index'
import { postData, get } from '../../utils/require'
import { cardType } from '../../utils/config'
import { logout } from '../../store/action/home'
import './index.less'

const { Option } = Select
const FormItem = Form.Item

const graphqlConfig = {
  user: graphql.updateUser,
  password: graphql.updatePassword
}

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
}

const findNameById = (id, list) => {
  const target = (list || []).find(ele => String(ele.id) === String(id)) || {}
  return target.name || ''
}

const passwordRules = {
  // pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()-+=]+$)(?![0-9\\W_!@#$%^&*`~()-+=]+$)(?![a-zA-Z0-9]+$)(?![a-zA-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9\\W_!@#$%^&*`~()-+=]+$)(?![0-9A-Z\\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()-+=]{8,20}$/,
  pattern: /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,20}/,
  message: '请输入8~20位包含大小写字母、数字和特殊符号的密码'
}

const logoutFunc = async (dispatch, history) => {
  const res = await logout(dispatch)
  if (res) {
    const { logout } = res.data || {}
    const { code, msg } = logout || {}
    if (code === 200) {
      // message.success('已退出登录')
      setTimeout(() => {
        history.push('/login')
      }, 1500);
    } else {
      message.error(msg)
    }
  }
}


const MyInfo = ({home, dispatch, history, getUser}) => {
  const [form] = Form.useForm();
  const formRef = useRef();
  useImperativeHandle(formRef, () => ({
    getForm: () => form,
  }));
  const detail = (home && home.curUser) || {}
  const [editData, setEditData] = useState({...(detail || {})})
  const [visible, setVisible] = useState(false)
  const [editType, setEditType] = useState('')
  const [editState, setEditState] = useState({})
  const [submitLoading, setSubmitLoading] = useState(false)
  const [formEditData, setFormEditData] = useState({})
  // const [loading, setLoading] = useState(false)
  // const [suffixText, setSuffixText] = useState('发送验证码')
  // const [count, setCount] = useState(60)
  // const [flag, setFlag] = useState('active')
  // console.log({detail})

  useEffect(() => {
    setEditData(detail)
  }, [detail])

  const changeData = (value, key) => {
    setEditData({ ...editData, [key]: value })
    // setEditState({ ...editState, [key]: true })
  }

  // const backCount = (count) => {
  //   if (count > 0) {

  //     setTimeout(() => {
  //       setSuffixText(`${count - 1}s后重新发送`)
  //       backCount(count - 1)
  //     }, 1000)
  //   } else {
  //     setFlag('active')
  //     setSuffixText(`发送验证码`)
  //   }
  // }

  // const getCode = (action) => {
  //   setLoading(true)
  //   const { contactNumber, telephoneCode } = detail
  //   const url = `/api/user/captcha?telephoneCode=${telephoneCode || ''}&contactNumber=${contactNumber}&template=${action}`
  //   get(url).then((res) => {
  //     setLoading(false)
  //     setFlag('disabled')
  //     setSuffixText(`60s后重新发送`)
  //     backCount(60)
  //   })
  // }

  // const getSuffix = (action) => {
  //   if (loading || flag !== 'active') {
  //     return <div className="suffixDisable">{suffixText}</div>
  //   }
  //   return <div className="suffixActive" onClick={()=>getCode(action)}>{suffixText}</div>
  // }

  const renderSelect = (list, key, {valueKey='id', nameKey='name', ...resProp}) => (
    <Select onChange={(v) => changeData(v, key)} style={{width: '100%' }} {...(resProp || {})} value={editData[key]}>
      {(list || []).map(ele => <Option key={ele[valueKey]} value={ele[valueKey]}>{ele[nameKey]}</Option>)}
    </Select>
  )

  const renderInput = (key, prop) => (
    <Input value={editData[key]} onChange={e => changeData(e.target.value, key)} style={{ width: '100%' }} {...(prop || {})} />
  )

  const fieldList = {
    contactNumber: { label: '手机号码', value: [(detail.telephoneCode && `+${detail.telephoneCode}`) || '', detail.contactNumber].filter(ele => !!ele).join('-')},
    name: { label: '真实姓名', value: editData.name || '', action: '修改', edit: renderInput('name')},
    cardType: { label: '证件类型', edit: renderSelect(cardType, 'cardType', {}), action: '保存', value: findNameById(editData.cardType, cardType)},
    cardNum: { label: '证件号码', edit: renderInput('cardNum'), action: '保存', value: editData.cardNum},
    email: { label: '邮箱', edit: renderInput('email'), action: '保存', value: editData.email},
    password: { label: '密码', value: '********', action: '修改'}
  }

  const submit = (params, key) => {
    const responseKey = { user: 'updateUser', password: 'updatePassword' }
    if (graphqlConfig[key] && !submitLoading) {
      setSubmitLoading(true)
      const payload =graphqlConfig[key](params)
      postData(payload).then(res => {
        setSubmitLoading(false)
        const resData = res && res.data && res.data[responseKey[key]]
        const { code, msg } = resData
        if (code === 200) {
          message.success(key === 'password'? '修改成功，请重新登录' : '修改成功')
          setEditData({ ...editData, ...params})
          if (key === 'password') {
            logoutFunc(dispatch, history)
          } else {
            const status = {...editState}
            Object.keys(params).forEach(key => status[key] = false)
            setEditState(status)
            if (getUser) {
              getUser()
            }
          }
          onCancel()
        } else {
          message.error(msg || '修改失败')
        }
      }).catch(() => {
        message.error('修改失败')
        setSubmitLoading(false)
      })
    }
  }

  const onClickAction = (key) => {
    if (['password'].includes(key)) {
      setEditType(key)
      const formData = key === 'name'? { name: detail.name} : {}
      setFormEditData(formData)
      setVisible(true);
    } else if (['cardType', 'cardNum', 'email', 'name'].includes(key)) {
      if (editState[key]) {
        submit({ [key]: editData[key]}, 'user')
      } else {
        setEditState({ ...editState, [key]: true })
      }
    }
  }

  const onCancel = () => {
    setVisible(false);
    setEditType('');
    form.resetFields()
  }

  const onFormSubmit = () => {
    form.validateFields().then(value=> {
      const params = {...value}
      if (editType === 'password') {
        if (value.password !== value.newPassword) {
          message.error('请填写相同的新密码')
          return
        }
        delete params.newPassword
        params.contactNumber = detail.contactNumber
      }
      submit(params, editType === 'password'? 'password' : 'user')
    })
  }

  // const suffix = getSuffix('updatePwd')
  const formList = {
    password: [
      // {
      //   formProps: { label: '短信验证码', name: 'captcha', rules: [{required: true, message: '请填写短信验证码'}] },
      //   comProps: { placeholder: "验证码", suffix }
      // },
      {
        formProps: { label: '原密码', name: 'oldPassword', rules: [{required: true, message: '请填写原密码'}] },
        comProps: { placeholder: "原密码" }
      },
      {
        formProps: {
          label: '新密码', name: 'password', 
          rules: [{required: true, message: '请填写新密码'}, passwordRules] 
        },
        comProps: { placeholder: "8~20位登录密码" }
      },
      {
        formProps: { label: '确认新密码', name: 'newPassword', rules: [{required: true, message: '请再次填写新密码'}] },
        comProps: { placeholder: "确认密码" }
      },
    ],
    name: [
      {
        formProps: { label: '真实姓名', name: 'name', rules: [{required: true, message: '请填写真实姓名'}] },
        comProps: { placeholder: "真实姓名" }
      },
    ]
  }

  return detail.user_id? (
    <div className='myRegister'>
      {Object.keys(fieldList).map(key => {
        const target = fieldList[key]
        const actionText = editState[key]? '保存' : '修改'
        return (
          <div key={key} className='userInfo'>
            <div className='userLabel'>{target.label}：</div>
            <div className='userValue'>{editState[key]? target.edit : target.value}</div>
            <div className='userAction' onClick={() => onClickAction(key)}>{target.action? actionText : ''}</div>
          </div>
        )
      })}

      <Modal title='修改' visible={visible} onOk={onFormSubmit} onCancel={onCancel} with={700} destroyOnClose>
        <div>
          <Form {...layout} initialValues={formEditData} form={form}>
            {/* <FormItem label='原密码' name="oldPassword" rules={[{ required: true }]}>
              <Input.Password placeholder='原密码' />
            </FormItem>
            <FormItem label='新密码' name="password" rules={[{ required: true }]}>
              <Input.Password placeholder='6~20位登录密码' />
            </FormItem>
            <FormItem label='确认新密码' name="newPassword" rules={[{ required: true }]}>
              <Input.Password placeholder='确认密码' />
            </FormItem> */}
            {(formList[editType] || []).map(ele => (
              <FormItem {...ele.formProps} key={ele.formProps.name}>
                {
                  !['name', 'captcha'].includes(ele.formProps.name)? (
                    <Input.Password {...ele.comProps} />
                  ) : (
                    <Input {...ele.comProps} />
                  )
                }
              </FormItem>
            ))}
          </Form>
        </div>
      </Modal>
    </div>
  ) : null
}

export default MyInfo