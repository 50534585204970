import indexGraphql from '../../graphql/index'
import { postData, get } from '../../utils/require'
import homeConstant from '../constant/home'

export const getSession = async dispatch => {
  const res = await postData(indexGraphql.getSession)
  if (res) {
    const { user } = (res && res.data && res.data.getSession) || {}
    const getUser = (res && res.data && res.data.getUser) || {}
    dispatch({
      type: homeConstant.GETSESSION,
      payload: { user, curUser: getUser }
    })
    return user
  }
}

export const getRegistrationDetail = async dispatch => {
  const res = await postData(indexGraphql.getRegistrationDetail)
  if (res) {
    const { registrationDetail } = res.data || {}
    dispatch({
      type: homeConstant.GETSESSION,
      payload: { registrationDetail: registrationDetail || {} }
    })
    return res;
  }
}

export const getUser = async dispatch => {
  const res = await postData(indexGraphql.getUser)
  if (res) {
    const { getUser } = res.data || {}
    dispatch({
      type: homeConstant.GETSESSION,
      payload: { curUser: getUser }
    })
    return getUser;
  }
  return {}
}

export const submit = async (params) => {
  const res = await postData({
    ...indexGraphql.updateRegistration,
    variables: {
      input: params
    }
  })
  return res
}

export const logout = async (dispatch) => {
  const res = await postData(indexGraphql.logout)
  if (res) {
    const { code, msg } = res && res.data && res.data.logout || {}
    if (code === 200) {
      dispatch({
        type: homeConstant.GETSESSION,
        payload: { user: null }
      })
    }
  }
  return res
}

export const getAllColleges = async (dispatch) => {
  const res = await postData({
    ...indexGraphql.getColleges,
    variables: {
      input: {
        // name: ''
      }
    }
  })
  if (res) {
    const colleges = res && res.data ? res.data.colleges : []
    dispatch({
      type: homeConstant.GETSESSION,
      payload: { colleges }
    })
  }
}

export const getInitData = async (params, dispatch) => {
  const res = await Promise.all([
    get('/api/news/list', { ...params, type: 2 }),
    get('/api/news/list', { ...params, type: 3 }),
    get('/api/news/list', { ...params, type: 4 })
  ])
  if (res) {
    const news = res || {}
    dispatch({
      type: homeConstant.GETSESSION,
      payload: {
        dynamicList: news[0].rows || [],
        newsList: news[1].rows || [],
        noticeList: news[2].rows || [],
      }
    })
  }
}