import React, { Component } from 'react';
import { Upload, message } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router';

@withRouter
class UploadCom extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            imageUrl: props.value,
            // previewVisible: false,
            // previewTitle: ''
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.imageUrl) {
            return {
                imageUrl: props.value
            }
        }
        return null
    }

    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('只能上传 JPG/PNG 文件!');
        }
        const isLt1M = file.size / 1024 / 1024 < 1;
        if (!isLt1M) {
            message.error('最大不超过 1MB!');
        }
        return isJpgOrPng && isLt1M;
    }
    handleChange = info => {
        const { onChange, history } = this.props;
        if (info.file.status === 'uploading') {
            this.setState({
                loading: true,
            })
            return;
        }
        if (info.file.status === 'done') {
            const { response } = info.file;
            const { data, code } = response || {}
            if (code === 205) {
                message.warning('登录状态已过期')
                setTimeout(() => {
                    history.push('/home')
                }, 1500);
                return;
            }
            this.setState({
                loading: false,
                imageUrl: data ? data.file_id : ''
            })
            onChange(data ? data.file_id : '')
        }
    };
    render() {
        const {
            loading,
            imageUrl,
            // previewVisible,
            // previewTitle,
        } = this.state;
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>上传</div>
            </div>
        );
        const initProps = {
            action: "/api/file/upload",
            onChange: this.handleChange,
            beforeUpload: this.beforeUpload,
            listType: "picture-card",
            showUploadList: false,
            // accept: '.png, .jpeg, .jpg',
        }
        return (
            <div>
                <Upload
                    {...initProps}
                >
                    {imageUrl ? <img src={`/api/file/${imageUrl}`} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload >
                {/* <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <img alt="example" style={{ width: '100%' }} src={`/api/file/${imageUrl}`} />
                </Modal> */}
                {/* <span style={{ color: '#999999' }}>支持JPG、PNG格式，1M以内</span> */}
            </div>

        )
    }
}

export default UploadCom;