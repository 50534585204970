const rule_required = { required: true, message: '此项为必填项' }

const config = {
	logo: {
		rules: [
			// rule_required
		],
		extra: '支持JPG、PNG格式，1M以内'
	},
	name: {
		rules: [
			rule_required,
			{ max: 50, message: '最多不超过50字' }
		],
		// extra: '提交后不可修改'
	},
	area: {
		rules: [
			// rule_required
		]
	},
	summary: {
		rules: [
			// rule_required,
			{ max: 1000, message: '最多不超过1000字' }
		]
	},
	pictures: {
		rules: [
			// rule_required
		],
		extra: '支持JPG、PNG格式，10M以内'
	},
	videoUrl: {
		rules: [
			// rule_required
		],
	},
	ppt: {
		rules: [
			rule_required
		],
		extra: '支持ppt、pptx、pot、potx、pps、ppsx、pptm、potm 大小不超过50M'
	},
	progress: {
		rules: [
			// rule_required
		]
	},
	unitName: {
		rules: [
			// rule_required
		]
	},
	establishmentDate: {
		rules: [
			// rule_required
		]
	},
	license: {
		rules: [
			// rule_required
		]
	},
	website: {
		rules: [
			// rule_required
		]
	},
	income: {
		rules: [
			// rule_required
		]
	},
	isFinance: {
		rules: [
			// rule_required
		]
	},
	isPatent: {
		rules: [
			// rule_required
		]
	},
	group: {
		rules: [
			rule_required
		]
	},
	region: {
		rules: [
			rule_required
		]
	},
	alumniAssociation: {
		rules: [
			rule_required
		]
	},
	field: {
		rules: [
			rule_required
		]
	},
	isPrivacy: {
		rules: [
			rule_required
		]
	},
	invest_getDate: {
		rules: [
			// rule_required
		]
	},
	invest_investor: {
		rules: [
			// rule_required
		]
	},
	invest_round: {
		rules: [
			// rule_required
		]
	},
	invest_amount: {
		rules: [
			// rule_required
		]
	},
	patent_name: {
		rules: [
			{ type: 'string', max: 200, message: '最长不超过200个字符' },
      		// { type: 'string', whitespace: true, message: '成果名称为必填项' },
		]
	},
	patent_type: {
		rules: [
			// rule_required
		]
	},
	patent_applicationNo: {
		rules: [
			// rule_required
		]
	},
	patent_publishedAt: {
		rules: [
			// rule_required
		]
	},
	member_name: {
		rules: [
			// rule_required
		]
	},
	member_nationality: {
		rules: [
			// rule_required
		]
	},
	member_contactNumber: {
		rules: [
			// rule_required,
			// { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '手机号格式不正确' },
		]
	},
	member_email: {
		rules: [
			// rule_required,
			{ type: 'email', message: 'email格式不正确' }
		]
	},
	member_isTeacher: {
		rules: [
			// rule_required
		]
	},
	member_isAlumnus: {
		rules: [
			// rule_required
		]
	},
	member_studentStatus: {
		rules: [
			// rule_required
		]
	},
	member_education: {
		rules: [
			// rule_required
		]
	},
	member_faculty: {
		rules: [
			// rule_required
		]
	},
	member_major: {
		rules: [
			// rule_required
		]
	},
	member_enterDate: {
		rules: [
			// rule_required
		]
	},
	member_highestUniversity: {
		rules: [
			// rule_required
		]
	},
	member_highestEducation: {
		rules: [
			// rule_required
		]
	},
	member_isFounder: {
		rules: [
			// rule_required
		]
	},
	member_takeOffice: {
		rules: [
			// rule_required
		]
	},
	member_job: {
		rules: [
			// rule_required
		]
	},
	member_ratio: {
		rules: [
			// rule_required
		]
	},
}

export default config;