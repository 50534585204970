import React, { useEffect, useState } from 'react'
import { Select, Input } from 'antd'
import './index.less'

const { Option } = Select;

const GlobalTel = (props) => {
  const { chooseList, onChange, placeholder } = props || {}
  const [tel, setTel] = useState('86');
  const [contactNumber, setContactNumber] = useState('')

  const setKeyConf = { tel: setTel, contactNumber: setContactNumber }

  useEffect(() => {
    if (props.value && props.value.tel) {
      setTel(props.value.tel)
    }
    if (props.value && props.value.contactNumber) {
      setContactNumber(props.value.contactNumber)
    }
  }, [props.value])

  const updateState = (value, key) => {
    const func = setKeyConf[key]
    if (func) {
      func(value)
    }
    if (onChange) {
      onChange({ tel, contactNumber, [key]: value })
    }
  }

  const options = (chooseList || [{ id: '86', name: '中国+86'}]).map(ele => (
    <Option key={ele.id} value={ele.id}>{ele.name}</Option>
  ))
  return (
    <div className="globalTel">
      <Select
        value={tel}
        onChange={(v) => updateState(v, 'tel')}
        style={{ width: '120px', marginRight: '8px' }}
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        dropdownClassName="dropdown-style"
        dropdownMatchSelectWidth={300}
      >
        {options}
      </Select>
      <Input
        value={contactNumber}
        onChange={(e) => updateState(e.target.value, 'contactNumber')}
        placeholder={placeholder || '手机号码'}
        style={{ width: '100%' }}
      />
    </div>
  )
}

export default GlobalTel